import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import dayjs from "dayjs";
import {
  DateTimeBuddhistEra as DateTimeBuddhistEraUtil,
  DateBuddhistEra as DateBuddhistEraUtil,
} from "../../utils/DateFormat";
import { Trade as TradeModel } from "../../models/Trade";
import { NumericFormat } from "react-number-format";

type Props = {
  trade: TradeModel | null;
};

const Purchase = (props: Props) => {
  return (
    <Box color={StyleVariables["color-text"]}>
      {/* Heading */}
      <Box borderBottom={`1px dashed ${StyleVariables["color-border"]}`}>
        {/* Branch Name && Ref Invoice Number */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={1}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.branchName}
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.refInvoiceNo || "X-XXXXXXXX-XXXX"}
          </Typography>
        </Box>
        {/* /.Branch Name && Ref Invoice Number */}

        {/* Create Date && Employee Name */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={2}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.userFirstName}{" "}
            {props.trade?.userLastName && props.trade?.userLastName[0]}.
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.createdAt
              ? DateTimeBuddhistEraUtil(props.trade?.createdAt)
              : `${DateBuddhistEraUtil(dayjs())} --:--`}
          </Typography>
        </Box>
        {/* /.Create Date && Employee Name */}
      </Box>
      {/* /.Heading */}

      {/* Product List */}
      <Box marginY={2}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={7}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              {props.trade?.productName} จำนวน {props.trade?.totalQuantity} ชิ้น
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              marginTop={1}
            >
              {`(นน.รวม${props.trade?.totalWeight} กรัม)`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            textAlign={"right"}
            sx={{ wordBreak: "break-word" }}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              <NumericFormat
                value={props.trade?.totalPay}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* /.Product List */}

      {/* Final Net Pay */}
      <Box
        paddingBottom={2}
        borderBottom={`1px dashed ${StyleVariables["color-border"]}`}
      >
        <Typography variant="subtitle2" fontWeight={"regular"}>
          ให้เงินลูกค้าโดย
        </Typography>
        <Grid container marginTop={1}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              เงินสด
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign={"right"}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              <NumericFormat
                value={props.trade?.netPay}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* /.Final Net Pay */}

      {/* Detail for Staff */}
      <Box marginTop={2} color={StyleVariables["color-placeholder"]}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          รายละเอียดต่างๆ
        </Typography>
        {/* Current Sell - Purchase Price */}
        <Typography variant="subtitle2" fontWeight={"regular"} marginTop={1}>
          <NumericFormat
            value={props.trade?.goldSellPrice}
            displayType="text"
            thousandSeparator
          />{" "}
          -{" "}
          <NumericFormat
            value={props.trade?.goldPurchasePrice}
            displayType="text"
            thousandSeparator
          />
        </Typography>
        {/* /.Current Sell - Purchase Price */}
      </Box>
      {props.trade?.currentStatus === "poor" && (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={1}
            marginTop={1}
            color={StyleVariables["color-error"]}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              ราคารับซื้อมาตรฐาน
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              textAlign={"right"}
            >
              <NumericFormat
                value={props.trade?.tradeHasItems[0]?.priceStandard}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            fontWeight={"medium"}
            color={StyleVariables["color-error"]}
            marginTop={2}
          >
            กรุณาตรวจสอบราคาอีกครั้ง
          </Typography>
        </>
      )}
      {/* /.Detail for Staff */}

      <Box marginY={2}>
        {props.trade?.deposits ? (
          <Box color={StyleVariables["color-error"]}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              *****************************
            </Typography>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="subtitle2" fontWeight={"regular"}>
                {`ให้เงินลูกค้า ${
                  props.trade?.deposits?.refInvoiceNo?.split("-")[1]
                }-${
                  props.trade?.deposits?.refInvoiceNo?.split("-")[2]
                } ทั้งสิ้น`}
              </Typography>
              <Typography variant="subtitle2" fontWeight={"regular"}>
                <NumericFormat
                  value={props.trade?.netPay || 0}
                  displayType="text"
                  thousandSeparator
                />
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Purchase;
