import { observable, makeAutoObservable, action } from "mobx";
import {
  Payment as PaymentModel,
  PaymentIsInvalid as PaymentIsInvalidModel,
} from "../models/Payment";
import { GetPaymentChannels as GetPaymentChannelsService } from "../services/MasterData";
import {
  Types as TypesPaymentConstant,
  KeysType as KeysTypePaymentConstant,
} from "../constants/Payment";

export default class PaymentStore {
  private initList: PaymentModel[] = [
    {
      channelCode: "99",
      channelName: "เงินสด",
      creditCardNumber: "",
      remark: "",
      amount: 0,
      imageId: "",
    },
  ];
  private initListIsInvalid: PaymentIsInvalidModel = {
    amount: false,
  };
  @observable creditCardChannels: PaymentModel[] = [];
  @observable qrCodeChannels: PaymentModel[] = [];
  @observable transferChannels: PaymentModel[] = [];
  @observable types: { label: string; value: string }[] = [];
  @observable list: PaymentModel[] = [...this.initList];
  @observable listIsInvalid: PaymentIsInvalidModel[] = [
    { ...this.initListIsInvalid },
  ];
  @observable percentCharge: number = 0;
  @observable cardPayIsInvalid: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onClearStore(cashPay: number) {
    this.list = [...this.initList];
    this.list[0].amount = cashPay;
    this.listIsInvalid = [{ ...this.initListIsInvalid }];
    this.cardPayIsInvalid = false;
  }

  @action
  async getChannels() {
    this.types = [...TypesPaymentConstant];
    const response = await GetPaymentChannelsService();
    this.creditCardChannels = response.data?.list?.filter(
      (item: any, index: number) =>
        item.channelName.toLocaleLowerCase().search("qr") < 0 &&
        item.channelName.toLocaleLowerCase().search("ลูกค้าโอน") < 0 &&
        index > 0
    );
    this.qrCodeChannels = response.data?.list?.filter(
      (item: any) => item.channelName.toLocaleLowerCase().search("qr") >= 0
    );
    this.transferChannels = response.data?.list?.filter(
      (item: any) =>
        item.channelName.toLocaleLowerCase().search("ลูกค้าโอน") >= 0
    );
    this.percentCharge = response.data?.percentCharge;
    if (!this.creditCardChannels.length)
      this.types = this.types.filter(
        (type) => type.value !== KeysTypePaymentConstant.allQrCode
      );
    if (!this.qrCodeChannels.length)
      this.types.filter(
        (type) => type.value !== KeysTypePaymentConstant.allCreditCard
      );
    if (!this.transferChannels.length)
      this.types.filter(
        (type) => type.value !== KeysTypePaymentConstant.allTransfer
      );
    if (
      !this.qrCodeChannels.length &&
      !this.transferChannels.length &&
      !this.creditCardChannels.length
    )
      this.types = this.types.filter(
        (type) =>
          type.value !== KeysTypePaymentConstant.allQrCode &&
          type.value !== KeysTypePaymentConstant.allCreditCard &&
          type.value !== KeysTypePaymentConstant.mix &&
          type.value !== KeysTypePaymentConstant.allTransfer
      );
  }

  @action
  onSetList(list: PaymentModel[]) {
    this.list = [...list];
    this.cardPayIsInvalid = false;
  }

  @action
  onSetListIsInvalid(listIsInvalid: PaymentIsInvalidModel[]) {
    this.listIsInvalid = [...listIsInvalid];
  }

  @action
  onSetCardPayIsInvalid(value: boolean) {
    this.cardPayIsInvalid = value;
  }

  @action
  onTypeChange(type: string, netPay: number) {
    let tempList: any[] = [];
    switch (type) {
      case KeysTypePaymentConstant.allQrCode:
        tempList = JSON.parse(JSON.stringify([...this.qrCodeChannels]));
        tempList[0].amount = netPay;
        this.onSetList([...tempList]);
        this.listIsInvalid = new Array(tempList.length).fill({
          ...this.initListIsInvalid,
        });
        break;
      case KeysTypePaymentConstant.allTransfer:
        tempList = JSON.parse(JSON.stringify([...this.transferChannels]));
        tempList[0].amount = netPay;
        this.onSetList([...tempList]);
        this.listIsInvalid = new Array(tempList.length).fill({
          ...this.initListIsInvalid,
        });
        break;
      case KeysTypePaymentConstant.allCreditCard:
        tempList = JSON.parse(JSON.stringify([...this.creditCardChannels]));
        this.onSetList([...tempList]);
        this.listIsInvalid = new Array(tempList.length).fill({
          ...this.initListIsInvalid,
        });
        break;
      case KeysTypePaymentConstant.mix:
        tempList = JSON.parse(
          JSON.stringify([
            ...this.initList,
            ...this.qrCodeChannels,
            ...this.transferChannels,
            ...this.creditCardChannels,
          ])
        );
        tempList[0].amount = netPay;
        this.onSetList([...tempList]);
        this.listIsInvalid = new Array(tempList.length).fill({
          ...this.initListIsInvalid,
        });
        break;
      default:
        break;
    }
  }

  @action
  onValidateForm(
    type: string,
    cardPay: number,
    chargeCard: number,
    netPay: number
  ) {
    let isValid = true;
    const totalPayInPaymentList = this.list.reduce(
      (n, { amount }) => n + amount,
      0
    );
    switch (type) {
      case KeysTypePaymentConstant.allQrCode:
      case KeysTypePaymentConstant.allCreditCard:
        if (totalPayInPaymentList !== netPay || totalPayInPaymentList <= 0) {
          this.listIsInvalid = this.listIsInvalid.map(
            (item: PaymentIsInvalidModel) => {
              item.amount = true;
              return item;
            }
          );
          isValid = false;
          this.onSetCardPayIsInvalid(true);
        }
        break;
      case KeysTypePaymentConstant.mix:
        const listCredits = this.list.filter((array_el) => {
          return (
            this.creditCardChannels.filter((anotherOne_el) => {
              return anotherOne_el.channelCode === array_el.channelCode;
            }).length > 0
          );
        });
        const totalCreditPay = listCredits.reduce(
          (n, { amount }) => n + amount,
          0
        );
        const totalAmount = this.list.reduce((n, { amount }) => n + amount, 0);
        if (
          !(
            totalCreditPay >= cardPay && totalCreditPay <= cardPay + chargeCard
          ) ||
          totalAmount > netPay
        ) {
          this.listIsInvalid = this.listIsInvalid.map(
            (item: PaymentIsInvalidModel) => {
              item.amount = true;
              return item;
            }
          );
          this.onSetCardPayIsInvalid(true);
          isValid = false;
        } else if (this.list[0].amount >= netPay) {
          this.listIsInvalid = this.listIsInvalid.map(
            (item: PaymentIsInvalidModel) => {
              item.amount = true;
              return item;
            }
          );
          isValid = false;
        }
        break;
      default:
        break;
    }
    return isValid;
  }

  @action
  getBodyChannel() {
    const body = this.list
      .filter((item) => item.amount)
      .map((item) => {
        return {
          channelCode: item.channelCode,
          amount: item.amount,
          ...(item.creditCardNumber && {
            creditCardNumber: item.creditCardNumber,
          }),
          ...(item.remark && { remark: item.remark }),
          ...(item.imageId && { storageId: item.imageId }),
        };
      });
    return body;
  }
}
