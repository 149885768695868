import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import dayjs from "dayjs";
import {
  Deposit as DepositModel,
  DepositRenew as DepositRenewModel,
} from "../../models/Deposit";
import { Customer as CustomerModel } from "../../models/Customer";
import { RenewBillTypeKey as RenewBillTypeKeyConstant } from "../../constants/Deposit";
import { NumericFormat, PatternFormat } from "react-number-format";
import ThaiBahtText from "thai-baht-text";

type Props = {
  deposit: DepositModel | null;
  isBillRenew?: boolean;
  depositRenew: DepositRenewModel | null;
  customer: CustomerModel | null;
  isComplete?: boolean;
};

const Deposit = (props: Props) => {
  const getLabelDepositRenewType = (type: string) => {
    switch (type) {
      case RenewBillTypeKeyConstant.toInterest:
        return "ต่อ";
      case RenewBillTypeKeyConstant.reduce:
        return "ลด";
      case RenewBillTypeKeyConstant.increase:
        return "เพิ่ม";
      default:
        return "";
    }
  };

  const renderTableProductList = () => {
    return (
      <Box sx={{ overflowX: "scroll" }}>
        <Box display={"flex"} textAlign={"center"} fontSize={12}>
          <Box
            padding={0.5}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
            borderRight={0}
            maxWidth={32}
            minWidth={32}
          >
            จำนวน
          </Box>
          <Box
            padding={0.5}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
            borderRight={0}
            maxWidth={218}
            minWidth={218}
          >
            รายการ
          </Box>
          <Box
            padding={0.5}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
            borderRight={0}
            maxWidth={65}
            minWidth={65}
          >
            น้ำหนัก (กรัม)
          </Box>
          <Box
            padding={0.5}
            maxWidth={49}
            minWidth={49}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
          >
            จำนวนเงิน
          </Box>
        </Box>

        <Box display={"flex"} textAlign={"center"} borderTop={0} fontSize={12}>
          <Box
            padding={0.5}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
            borderRight={0}
            borderTop={0}
            maxWidth={32}
            minWidth={32}
          >
            {props.deposit?.totalQuantity}
          </Box>
          <Box
            padding={0.5}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
            borderRight={0}
            borderTop={0}
            maxWidth={218}
            minWidth={218}
          >
            {props.deposit?.productName}
          </Box>
          <Box
            padding={0.5}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
            borderRight={0}
            borderTop={0}
            maxWidth={65}
            minWidth={65}
          >
            {props.deposit?.totalWeight}
          </Box>
          <Box
            padding={0.5}
            maxWidth={49}
            minWidth={49}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
            borderTop={0}
          >
            <NumericFormat
              value={
                props.isBillRenew
                  ? props.depositRenew?.newDepositPrice
                  : props.deposit?.depositPrice
              }
              displayType="text"
              thousandSeparator
            />
          </Box>
        </Box>

        <Box display={"flex"} textAlign={"center"} borderTop={0} fontSize={12}>
          <Box
            padding={0.5}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
            borderRight={0}
            borderTop={0}
            maxWidth={332}
            minWidth={332}
          >
            {ThaiBahtText(
              props.isBillRenew
                ? props.depositRenew?.newDepositPrice || 0
                : props.deposit?.depositPrice || 0
            )}
          </Box>
          <Box
            padding={0.5}
            maxWidth={49}
            minWidth={49}
            border={`0.5px solid ${StyleVariables["color-black"]}`}
            borderTop={0}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box color={StyleVariables["color-text"]}>
      <Box marginTop={2} color={StyleVariables["color-black"]}>
        {/* Heading */}
        <Grid container columnSpacing={1}>
          <Grid item xs={8}>
            <Typography variant="subtitle1" fontWeight={"regular"}>
              ใบขายฝากสินค้าของเก่า{props.isComplete ? " (สำเนา)" : ""}
            </Typography>
            <Typography variant="subtitle2" fontWeight={"regular"} marginY={1}>
              มีกำหนด {props.deposit?.period} เดือน
            </Typography>
            <Typography
              variant="caption"
              fontWeight={"regular"}
              display={"inline"}
            >
              พนักงาน{" "}
            </Typography>
            <Typography
              variant="caption"
              fontWeight={"regular"}
              display={"inline"}
              sx={{ textDecoration: "underline" }}
            >
              {props.deposit?.userFirstName}{" "}
              {props.deposit?.userLastName && props.deposit?.userLastName[0]}.
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            position={"relative"}
          >
            <Typography variant="caption" fontWeight={"regular"}>
              {props.deposit?.createdAt &&
              (!props.isBillRenew || props.depositRenew?.refInvoiceNo)
                ? dayjs
                    .utc(props.deposit?.createdAt)
                    .local()
                    .locale("th")
                    .format("DD/M/BB")
                : `${dayjs.utc().local().locale("th").format("DD/M/BB")}`}
            </Typography>
            <Typography variant="subtitle1" fontWeight={"regular"}>
              เลขที่{" "}
              {!props.isBillRenew
                ? `${
                    props.deposit?.refInvoiceNo
                      ? `${props.deposit?.refInvoiceNo?.split("-")[1]}-${
                          props.deposit?.refInvoiceNo?.split("-")[2]
                        }`
                      : "XX-XXXX"
                  }`
                : `${
                    props.depositRenew?.refInvoiceNo
                      ? `${props.depositRenew?.refInvoiceNo?.split("-")[1]}-${
                          props.depositRenew?.refInvoiceNo?.split("-")[2]
                        }`
                      : "XX-XXXX"
                  }`}
            </Typography>
            <Typography variant="subtitle1" fontWeight={"regular"}>
              <NumericFormat
                value={
                  props.isBillRenew
                    ? props.depositRenew?.newDepositPrice
                    : props.deposit?.depositPrice
                }
                displayType="text"
                thousandSeparator
              />{" "}
              บ.
            </Typography>
            <Typography variant="subtitle1" fontWeight={"regular"}>
              {props.deposit?.totalWeight} กรัม
            </Typography>
            {props.deposit?.period !== 5 ? (
              <Typography
                variant="body2"
                fontWeight={"bold"}
                position={"absolute"}
                right={0}
                bottom={props.isBillRenew ? 72 : 48}
              >
                {props.deposit?.period} ด
              </Typography>
            ) : null}
            <Typography variant="subtitle2" fontWeight={"regular"}>
              {props.deposit?.branchName}
            </Typography>
            {props.isBillRenew ? (
              <Typography variant="subtitle2" fontWeight={"regular"}>
                {` ${getLabelDepositRenewType(props.deposit?.type || "")} `}
                {props.deposit?.refInvoiceNo
                  ? `${props.deposit?.refInvoiceNo?.split("-")[1]}-${
                      props.deposit?.refInvoiceNo?.split("-")[2]
                    }`
                  : "XX-XXXX"}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        {/* /.Heading */}

        {/* Customer */}
        <Box marginY={2}>
          <Typography
            variant="subtitle2"
            fontWeight={"regular"}
            marginBottom={1}
          >
            ข้อมูลลูกค้า
          </Typography>
          <Box display={"flex"}>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={12}
              lineHeight={"18px"}
              display={"inline"}
            >
              ชื่อ-นามสกุล
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={12}
              lineHeight={"18px"}
              display={"inline"}
              paddingLeft={0.5}
              sx={{
                textDecoration: props.customer?.firstName
                  ? "underline"
                  : "none",
              }}
            >
              {props.customer?.firstName
                ? `${props.customer?.firstName} ${
                    props.customer?.lastName || ""
                  }`
                : "-"}
            </Typography>
          </Box>
          <Box display={"flex"}>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={12}
              lineHeight={"18px"}
              display={"inline"}
            >
              เบอร์โทร
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={12}
              lineHeight={"18px"}
              display={"inline"}
              paddingLeft={0.5}
              sx={{
                textDecoration: props.customer?.firstName
                  ? "underline"
                  : "none",
              }}
            >
              {props.customer?.phoneNumber ? (
                <PatternFormat
                  displayType="text"
                  format="###-###-####"
                  value={props.customer?.phoneNumber}
                />
              ) : (
                "-"
              )}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={12}
              lineHeight={"18px"}
              display={"inline"}
              paddingLeft={5}
            >
              เลขบัตรประชาชน
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={12}
              lineHeight={"18px"}
              display={"inline"}
              paddingLeft={0.5}
              sx={{
                textDecoration: props.customer?.firstName
                  ? "underline"
                  : "none",
              }}
            >
              {` ${props.customer?.citizenId || "-"} `}
            </Typography>
          </Box>
          <Box display={"flex"}>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={12}
              lineHeight={"18px"}
              minWidth={22}
            >
              {"ที่อยู่ "}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={12}
              lineHeight={"18px"}
              paddingLeft={0.5}
              sx={{
                textDecoration: props.customer?.firstName
                  ? "underline"
                  : "none",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {` ${props.customer?.address || "-"} `}
            </Typography>
          </Box>
        </Box>
        {/* /.Customer */}

        {renderTableProductList()}

        {/* Remark */}
        <Box marginY={2}>
          <Typography fontSize={10} lineHeight={"18px"}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {`ข้าพเจ้าได้ตกลงขายฝากสินค้าของเก่าข้างต้นนี้และขอรับรองว่าสินค้าของเก่าที่ข้าพเจ้านำมาขายฝากนี้ เป็นของข้าพเจ้าอย่างถูกต้องตามกฎหมายและข้าพเจ้าจะมาซื้อคืนภายในกำหนดข้างต้นถ้าเกิน กำหนด
            ข้าพเจ้ายินยอมสละสิทธิ์สินค้าข้างต้นนี้ และมอบให้ตกเป็นกรรมสิทธิ์ของผู้รับซื้อฝากทันที กรณีมีปัญหาขึ้น 
            ข้าพเจ้าจะรับผิดชอบแต่เพียงผู้เดียว`}
          </Typography>
        </Box>
        {/* /.Remark */}

        {/* Footer */}
        <Grid container>
          <Grid item xs={6} alignSelf={"center"}>
            <Typography
              variant="caption"
              fontWeight={"regular"}
              display={"block"}
              marginBottom={2}
            >
              กำหนดคืนวันที่{" "}
              {` ${dayjs()
                .add(props.deposit?.period || 0, "month")
                .format("DD/M/BB")} `}
            </Typography>
            {props.isBillRenew ? (
              props.deposit?.type !== RenewBillTypeKeyConstant.increase ? (
                <Box marginTop={2} color={StyleVariables["color-success"]}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"regular"}
                    display={"block"}
                  >
                    *********************
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"regular"}
                    fontSize={14}
                    lineHeight={"18px"}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    รับเงินลูกค้าทั้งสิ้น{" "}
                    <NumericFormat
                      value={
                        !props.isBillRenew
                          ? props.deposit?.depositPrice
                          : props.deposit?.netPay
                      }
                      displayType="text"
                      thousandSeparator
                    />
                  </Typography>
                </Box>
              ) : (
                <Box marginTop={2} color={StyleVariables["color-error"]}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"regular"}
                    display={"block"}
                    overflow={"hidden"}
                  >
                    *********************
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"regular"}
                    fontSize={14}
                    lineHeight={"18px"}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    ให้เงินลูกค้าทั้งสิ้น{" "}
                    <NumericFormat
                      value={props.deposit.netPay}
                      displayType="text"
                      thousandSeparator
                    />
                  </Typography>
                </Box>
              )
            ) : (
              <Box marginTop={2} color={StyleVariables["color-error"]}>
                <Typography
                  variant="subtitle2"
                  fontWeight={"regular"}
                  display={"block"}
                >
                  *********************
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight={"regular"}
                  fontSize={14}
                  lineHeight={"18px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  ให้เงินลูกค้าทั้งสิ้น{" "}
                  <NumericFormat
                    value={props.deposit?.depositPrice}
                    displayType="text"
                    thousandSeparator
                    style={{ textAlign: "right" }}
                  />
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={6} paddingLeft={0.5}>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={10}
              lineHeight={"18px"}
            >
              *กรุณาตรวจนับเงินก่อนออกจากร้านมิฉะนั้นทาง ร้านจะไม่รับผิดชอบ
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={10}
              lineHeight={"18px"}
              paddingY={0.5}
              whiteSpace={"pre-line"}
            >
              {`*สัญญานี้เฉพาะผู้ขายฝากเท่านั้นจึงมีสิทธิ์มาซื้อ
              คืนทรัพย์สิน`}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"regular"}
              fontSize={10}
              lineHeight={"18px"}
            >
              *บัตรหายถือว่าสละสิทธิ์
            </Typography>
          </Grid>
        </Grid>
        {/* /Footer */}
      </Box>

      {props.deposit?.currentStatus === "poor" && !props.isBillRenew ? (
        <Box marginTop={2}>
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            color={StyleVariables["color-placeholder"]}
          >
            รายละเอียดต่างๆ
          </Typography>

          {/* Deposit Price Standard */}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={1}
            marginTop={1}
            color={StyleVariables["color-error"]}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              ราคารับฝากมาตรฐาน
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              textAlign={"right"}
            >
              <NumericFormat
                value={props.deposit?.depositPriceStandard}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Box>
          {/* /.Deposit Price Standard */}

          <Typography
            variant="subtitle2"
            fontWeight={"medium"}
            color={StyleVariables["color-error"]}
            marginTop={2}
          >
            กรุณาตรวจสอบราคาอีกครั้ง
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default Deposit;
