import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Typography, Button, Grid, Drawer } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import StyleVariables from "../assets/styles/Variable.module.scss";
import MainLayout from "../layouts/Main";
import { useStores } from "../stores";
import {
  ScrollToTop as ScrollToTopUtil,
  ScrollToElementId as ScrollToElementIdUtil,
} from "../utils/ScrollToElement";
import { ChargeRoundUp as ChargeRoundUpUtil } from "../utils/NumberRounding";
import { RefInvoice as RefInvoiceConstant } from "../constants/Gold";
import { KeysType as KeyTypePaymentConstant } from "../constants/Payment";
import { RenewBillTypeKey as RenewBillTypeKeyConstant } from "../constants/Deposit";
import {
  CreateToInterest as CreateToInterestModel,
  CreateToInterest as CreateToInterestDepositModel,
  CreateIncrease as CreateIncreaseDepositModel,
} from "../models/Deposit";
import { Create as CreateCustomerModel } from "../models/Customer";
import {
  CreateDepositRenewToInterest as CreateDepositRenewToInterestService,
  CreateDepositRenewReduce as CreateDepositRenewReduceService,
  CreateDepositRenewIncrease as CreateDepositRenewIncreaseService,
  CreateDepositRenewPurchase as CreateDepositRenewPurchaseService,
  CreateDepositReturn as CreateDepositReturnService,
  SubmitDepositById as SubmitDepositByIdService,
  GetLatestDepositsReturned as GetLatestDepositsReturnedService,
  GetDepositById as GetDepositByIdService,
  GetLatestRefInvoiceNo as GetLatestRefInvoiceNoService,
} from "../services/Deposit";
import { SubmitTradeById as SubmitTradeByIdService } from "../services/Trade";
import { Create as CreateCustomerService } from "../services/Customer";
import { OnPrintPdf as OnPrintPdfService } from "../services/Storage";
import { Deposit as DepositModel } from "../models/Deposit";
import { Payment as PaymentModel } from "../models/Payment";
import { Trade as TradeModel } from "../models/Trade";
import TextFieldInput from "../components/inputs/TextField";
import InformationModal from "../components/modals/Information";
import LoadProgressing from "../components/LoadProgressing";
import AlertSnackbar from "../components/snackbars/Alert";
import SimpleAccordion from "../components/accordions/Simple";
import ReturnForm from "../components/forms/Return";
import PaymentForm from "../components/forms/Payment";
import CustomerForm from "../components/forms/Customer";
import BillModal from "../components/modals/Bill";
import DepositBillModal from "../components/modals/DepositBill";
import ReturnBill from "../components/bills/Return";
import DepositBill from "../components/bills/Deposit";
import PurchaseBill from "../components/bills/Purchase";
import ReturnListTable from "../components/tables/ReturnList";

type Props = {};

const Return = observer((props: Props) => {
  const { MainStore, DepositStore, CustomerStore, PaymentStore, UserStore } =
    useStores();
  const [billNoSearchText, setBillSearchText] = useState<string>("");
  const [billNoSearchTextIsInvalid, setBillNoSearchTextIsInvalid] =
    useState<boolean>(false);
  const [billBookNoSearchText, setBillBookSearchText] = useState<string>("");
  const [billBookNoSearchTextIsInvalid, setBillBookNoSearchTextIsInvalid] =
    useState<boolean>(false);
  const [isLoadProgressing, setIsLoadProgressing] = useState<boolean>(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState<boolean>(false);
  const [messageFailModal, setMessageFailModal] = useState<string>("");
  const [scrollToElementId, setScrollToElementId] = useState<string>("");
  const [isOpenSuccessSnackbar, setIsOpenSuccessSnackbar] =
    useState<boolean>(false);
  const [isBillModalOpen, setIsBillModalOpen] = useState<boolean>(false);
  const [isDepositBillModalOpen, setIsDepositBillModalOpen] =
    useState<boolean>(false);
  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [deposits, setDeposits] = useState<any[]>([]);
  const [deposit, setDeposit] = useState<DepositModel | null>(null);
  const [payments, setPayments] = useState<PaymentModel[]>([]);
  const [isCompleteBillModalOpen, setIsCompleteBillModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    onGetLatestRefInvoiceNo();
  }, []);

  const onGetLatestRefInvoiceNo = async () => {
    const response = await GetLatestRefInvoiceNoService();
    setBillBookSearchText(
      response.data?.refInvoiceNo
        ? `${response.data.refInvoiceNo[2]}${response.data.refInvoiceNo[3]}`
        : ""
    );
  };
  const onReset = (isResetAll: boolean) => {
    DepositStore.onClearStore();
    CustomerStore.onClearStore();
    PaymentStore.onClearStore(0);
    if (isResetAll) {
      setBillSearchText("");
      setBillNoSearchTextIsInvalid(false);
    }
    ScrollToTopUtil();
  };
  const onBillNoSearchTextChange = (value: string) => {
    const newString = value.slice(0, 4);
    setBillSearchText(newString);
    setBillNoSearchTextIsInvalid(false);
  };
  const onBillBookNoSearchTextChange = (value: string) => {
    const newString = value.slice(0, 2);
    setBillBookSearchText(newString);
    setBillBookNoSearchTextIsInvalid(false);
  };
  const onSearchDepositByBillNo = async () => {
    setBillBookNoSearchTextIsInvalid(billBookNoSearchText?.length !== 2);
    setBillNoSearchTextIsInvalid(billNoSearchText?.length !== 4);
    if (billBookNoSearchText?.length === 2 && billNoSearchText?.length === 4) {
      setIsLoadProgressing(true);
      onReset(false);
      const response = await DepositStore.onGetDepositByBillNo(
        {
          "ref-invoice-no": `${RefInvoiceConstant.deposit}-${billBookNoSearchText}-${billNoSearchText}`,
        },
        true
      );
      setIsLoadProgressing(false);
      if (!response.success) {
        setMessageFailModal(
          `${
            response.message ||
            `ไม่พอข้อมูลเลขที่บิล(ต่อบิล) ${billNoSearchText}`
          } (${response.code})`
        );
        setIsFailModalOpen(true);
        return;
      }
      if (response.data?.deposit?.customer) {
        const customer = response?.data?.deposit?.customer;
        CustomerStore.onSetForm({
          id: customer.id,
          citizenId: customer.citizenId || "",
          firstName: customer.firstName || "",
          lastName: customer.lastName || "",
          address: customer.address || "",
          citizenCardImageId: customer.citizenCardImage?.id || "",
          customerImageId: customer.customerImage?.id || "",
          phoneNumber: customer.phoneNumber || "",
        });
      }
      ScrollToElementIdUtil("deposit-information-content");
    }
  };
  const onCloseFailModal = () => {
    setIsFailModalOpen(false);
    setMessageFailModal("");
    scrollToElementId && ScrollToElementIdUtil(scrollToElementId);
    setScrollToElementId("");
  };
  const onDepositInputChange = (key: string, value: any) => {
    const formObj: any = { ...DepositStore.form };
    const formIsInvalidObj: any = { ...DepositStore.formIsInvalid };
    formObj[key] = value;
    switch (key) {
      case "paymentType":
        if (value !== KeyTypePaymentConstant.allCreditCard) {
          formObj.cardPay = 0;
          formObj.percentChargeCard = 0;
          formObj.chargeCard = 0;
          formObj.netPay = formObj.totalPay;
        } else {
          formObj.cardPay = formObj.totalPay;
          formObj.percentChargeCard = PaymentStore.percentCharge;
          formObj.chargeCard = ChargeRoundUpUtil(
            formObj.totalPay * (PaymentStore.percentCharge / 100)
          );
          formObj.netPay = formObj.totalPay + formObj.chargeCard;
        }
        PaymentStore.onClearStore(formObj.totalPay);
        PaymentStore.onTypeChange(value, formObj.netPay);
        break;

      case "cardPay":
        formObj.cardPay = value;
        formObj.percentChargeCard = PaymentStore.percentCharge;
        formObj.chargeCard = ChargeRoundUpUtil(
          value * (PaymentStore.percentCharge / 100)
        );
        formObj.netPay = formObj.totalPay + formObj.chargeCard;
        formIsInvalidObj.cardPay =
          formObj.paymentType === KeyTypePaymentConstant.mix &&
          value >= formObj.totalPay;
        PaymentStore.onTypeChange(formObj.paymentType, formObj.netPay);
        break;
      default:
        break;
    }
    DepositStore.onSetForm({ ...formObj });
    DepositStore.onSetFormIsInvalid({ ...formIsInvalidObj });
  };
  const bodyTradeForDepositPurchaseBill = () => {
    const body: TradeModel = {
      userFirstName: UserStore.user.employeeFirstName,
      userLastName: UserStore.user.employeeLastName,
      branchName: UserStore.user.companyName,
      branchCode: UserStore.user.companyCode,
      productType: DepositStore.formRenew.purchaseType,
      categoryCode: DepositStore.form.categoryCode,
      categoryName: DepositStore.form.categoryName,
      productCode: DepositStore.form.productCode,
      productName: DepositStore.form.productName,
      productPercentage: DepositStore.form.productPercentage,
      goldSellPrice: DepositStore.formRenew.goldSellPrice,
      goldPurchasePrice: DepositStore.formRenew.goldPurchasePrice,
      goldPriceDateTime: DepositStore.formRenew.goldPriceDateTime,
      totalQuantity: DepositStore.form.totalQuantity,
      totalWeight: DepositStore.form.totalWeight,
      totalGoldPrice: 0,
      totalLabourStandard: 0,
      totalLabour: 0,
      totalPay: DepositStore.form.totalPay || 0,
      cardPay: 0,
      paymentType: "1",
      netPay: DepositStore.form.netPay || 0,
      tradeHasItems: [
        {
          quantity: DepositStore.form.totalQuantity,
          weight:
            DepositStore.form.totalWeight / DepositStore.form.totalQuantity,
          labourStandard: 0,
          labour: 0,
          type: "OLD",
          goldPrice: 0,
          priceStandard: DepositStore.formRenew.purchasePriceStandard,
          price: DepositStore.form.totalPay || 0,
          priceNegotiation: 0,
        },
      ],
      totalPriceNegotiation: 0,
      currentStatus: DepositStore.formRenew.purchasePriceCurrentStatus,
      standardExcellent: DepositStore.formRenew.purchaseStandardExcellent,
      standardGood: DepositStore.formRenew.purchaseStandardGood,
      standardFair: DepositStore.formRenew.purchaseStandardFair,
      standardPoor: DepositStore.formRenew.purchaseStandardPoor,
      deposits: DepositStore.form,
    };
    return body;
  };
  const onSubmit = () => {
    const isValidFormDeposit = DepositStore.onValidateForm();
    const isValidFormPayment = PaymentStore.onValidateForm(
      DepositStore.form.paymentType || "",
      DepositStore.form.cardPay || 0,
      DepositStore.form.chargeCard || 0,
      DepositStore.form.netPay || 0
    );
    if (!isValidFormDeposit) {
      setScrollToElementId("deposit-information-content");
      DepositStore.form.type !== RenewBillTypeKeyConstant.purchase
        ? setMessageFailModal(
            "กรุณาตรวจสอบข้อมูลการฝากให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
          )
        : setMessageFailModal(
            Number(DepositStore.form.netPay) >= 0
              ? "กรุณาตรวจสอบข้อมูลการฝากให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
              : "ไม่สามารถทำรายการได้ เนื่องจากมูลค่าทองรับซื้อ น้อยกว่า ราคารับฝาก"
          );
      setIsFailModalOpen(true);
      return;
    }
    if (!isValidFormPayment) {
      setScrollToElementId("payment-information-content");
      setMessageFailModal(
        "กรุณาตรวจสอบข้อมูลการชำระเงินให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
      );
      setIsFailModalOpen(true);
      return;
    }
    DepositStore.form.type === RenewBillTypeKeyConstant.return ||
    DepositStore.form.type === RenewBillTypeKeyConstant.purchase
      ? setIsBillModalOpen(true)
      : setIsDepositBillModalOpen(true);
  };
  const onCreateReturn = async () => {
    setIsBillModalOpen(false);
    setIsDepositBillModalOpen(false);
    setIsLoadProgressing(true);
    const bodyDepositReturn = DepositStore.getBodyRenewToInterest();
    const body: CreateToInterestModel = {
      ...bodyDepositReturn,
      dailyGoldTime: MainStore.goldDateTime,
      totalPay: DepositStore.form.depositPrice,
      payment: {
        ...bodyDepositReturn.payment,
        channel: PaymentStore.getBodyChannel(),
      },
    };
    const response = await CreateDepositReturnService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลการคืนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    // Submit PDF
    await onSubmitDeposit(response.data?.id);
    // /.Submit PDF
    setIsLoadProgressing(false);
    setIsOpenSuccessSnackbar(true);
    onReset(true);
  };
  const onCreateRenewDeposit = async () => {
    setIsBillModalOpen(false);
    setIsDepositBillModalOpen(false);
    setIsLoadProgressing(true);
    const customerBody: CreateCustomerModel | null = CustomerStore.getBody();
    const depositRenewToInterestBody = DepositStore.getBodyRenewToInterest();
    const bodyRenewDepositToInterest: CreateToInterestDepositModel = {
      ...depositRenewToInterestBody,
      payment: {
        ...depositRenewToInterestBody.payment,
        channel: PaymentStore.getBodyChannel(),
      },
    };
    const bodyRenewDepositIncrease: CreateIncreaseDepositModel = {
      ...DepositStore.getBodyRenewIncrease(),
    };
    let customerId: string = "";
    let responseCreateDeposit: any = null;
    // Create Customer
    if (customerBody) {
      const responseCustomer = await onCreateCustomer(customerBody);
      if (!responseCustomer) return;
      customerId = responseCustomer?.id;
    }
    // /.Create Customer

    // Create Renew Deposit
    switch (DepositStore.form.type) {
      case RenewBillTypeKeyConstant.toInterest:
        responseCreateDeposit = await onCreateRenewToInterestDeposit({
          ...bodyRenewDepositToInterest,
          ...(customerId && { customerId }),
        });
        break;
      case RenewBillTypeKeyConstant.reduce:
        responseCreateDeposit = await onCreateRenewReduceDeposit({
          ...bodyRenewDepositToInterest,
          totalPay:
            bodyRenewDepositToInterest.totalPay -
            (DepositStore.form.interest || 0),
          ...(customerId && { customerId }),
        });
        break;
      case RenewBillTypeKeyConstant.increase:
        responseCreateDeposit = await onCreateRenewIncreaseDeposit({
          ...bodyRenewDepositIncrease,
          ...(customerId && { customerId }),
        });
        break;
      case RenewBillTypeKeyConstant.purchase:
        responseCreateDeposit = await onCreateRenewPurchaseDeposit({
          ...bodyRenewDepositIncrease,
          ...(customerId && { customerId }),
        });
        break;
      default:
        break;
    }
    // /.Create Renew Deposit
    if (!responseCreateDeposit) {
      setIsLoadProgressing(false);
      return;
    }
    // Submit PDF
    DepositStore.form.type !== RenewBillTypeKeyConstant.purchase
      ? await onSubmitDeposit(responseCreateDeposit.id)
      : await onSubmitTrade(responseCreateDeposit.id);
    // /.Submit PDF
    setIsLoadProgressing(false);
    setIsOpenSuccessSnackbar(true);
    onReset(true);
  };
  const onCreateRenewToInterestDeposit = async (
    body: CreateToInterestDepositModel
  ) => {
    const response = await CreateDepositRenewToInterestService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลการฝากไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };
  const onCreateRenewReduceDeposit = async (
    body: CreateToInterestDepositModel
  ) => {
    const response = await CreateDepositRenewReduceService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลการฝากไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };
  const onCreateRenewIncreaseDeposit = async (
    body: CreateToInterestDepositModel
  ) => {
    const response = await CreateDepositRenewIncreaseService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลการฝากไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };
  const onCreateRenewPurchaseDeposit = async (
    body: CreateToInterestDepositModel
  ) => {
    const response = await CreateDepositRenewPurchaseService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลการฝากไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };
  const onCreateCustomer = async (body: CreateCustomerModel) => {
    const response = await CreateCustomerService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลลูกค้าไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };

  const onSubmitTrade = async (id: string) => {
    const response = await SubmitTradeByIdService(id);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message ||
          "เกิดข้อผิดพลาดในการสร้างบิล กรุณาพิมพ์บิลใหม่ในรายการประวัติการรับซื้อ"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
  };
  const onSubmitDeposit = async (id: string) => {
    const response = await SubmitDepositByIdService(id);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message ||
          "เกิดข้อผิดพลาดในการสร้างบิล กรุณาพิมพ์บิลใหม่ในรายการประวัติการคืน"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
  };

  const onOpenList = () => {
    getDepositsReturned();
    setIsOpenList(true);
  };
  const onCloseList = () => {
    setIsOpenList(false);
    setDeposits([]);
  };
  const getDepositsReturned = async () => {
    setIsLoadingList(true);
    const response = await GetLatestDepositsReturnedService();
    response.success && setDeposits(response.data);
    setIsLoadingList(false);
  };
  const onOpenCompleteBillModal = async (id: string) => {
    setIsLoadProgressing(true);
    const response = await GetDepositByIdService(id);
    setIsLoadProgressing(false);
    const { payments, customer, ...data } = response.data?.deposit;
    if (response.success) {
      setIsOpenList(false);
      setDeposit(data);
      setPayments(payments);
      setIsCompleteBillModalOpen(true);
    }
  };
  const onCloseCompleteBillModal = () => {
    setIsCompleteBillModalOpen(false);
    setDeposit(null);
    setPayments([]);
  };
  const onPrintBillPdfHistory = async (id: string) => {
    setIsCompleteBillModalOpen(false);
    setIsLoadProgressing(true);
    const response = await OnPrintPdfService(id);
    setIsLoadProgressing(false);
    if (!response.success) {
      setMessageFailModal(
        `${response.message || "เกิดข้อผิดพลาดในการพิมพ์ข้อมูลบิลการคืน"} (${
          response.code
        })`
      );
      setIsFailModalOpen(true);
      return false;
    }
  };
  const generateReturnTitleBill = (type: string, isCopy?: boolean) => {
    switch (type) {
      case RenewBillTypeKeyConstant.toInterest:
        return isCopy ? "คืน ต่อดอก (สำเนา)" : "คืน ต่อดอก";
      case RenewBillTypeKeyConstant.reduce:
        return isCopy ? "คืน ลดต้น (สำเนา)" : "คืน ลดต้น";
      case RenewBillTypeKeyConstant.increase:
        return isCopy ? "คืน เพิ่มยอด (สำเนา)" : "คืน เพิ่มยอด";
      case RenewBillTypeKeyConstant.purchase:
        return isCopy ? "คืน ตัดขาย (สำเนา)" : "คืน ตัดขาย";
      default:
        return isCopy ? "คืน (สำเนา)" : "คืน";
    }
  };

  return (
    <MainLayout>
      <>
        <Box
          display={{
            xs: MainStore.isOpenSidebar ? "block" : "flex",
            md: "flex",
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          textAlign={"center"}
        >
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={StyleVariables["color-header"]}
          >
            การคืน
          </Typography>
          <Box>
            <Button variant="contained-refund" onClick={onOpenList}>
              ดูประวัติการคืน
            </Button>
          </Box>
        </Box>
        {/* Search Bill */}
        <Grid
          container
          spacing={2}
          justifyContent={"flex-end"}
          alignItems={"flex-start"}
          marginTop={2}
        >
          <Grid item xs={12} md={4} lg={2}>
            <TextFieldInput
              name="billBookNoSearchText"
              label="เล่มที่"
              type="number"
              inputMode="numeric"
              placeholder="XX"
              value={billBookNoSearchText}
              isError={billBookNoSearchTextIsInvalid}
              isShowErrorMessage={true}
              errorMessage="กรุณากรอกเลขที่เล่มให้ถูกต้อง"
              handleOnChange={(value: string) => {
                onBillBookNoSearchTextChange(value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <TextFieldInput
              name="billNoSearchText"
              label="เลขที่(ต่อบิล)"
              type="number"
              inputMode="numeric"
              placeholder="XXXX"
              value={billNoSearchText}
              isError={billNoSearchTextIsInvalid}
              isShowErrorMessage={true}
              errorMessage="กรุณากรอกเลขที่บิลให้ถูกต้อง"
              handleOnChange={(value: string) => {
                onBillNoSearchTextChange(value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2} marginTop={"38px"}>
            <Button
              variant="outlined"
              fullWidth
              onClick={onSearchDepositByBillNo}
            >
              ค้นหา
            </Button>
          </Grid>
        </Grid>
        {/* /.Search Bill */}

        <Box marginTop={4}>
          {/* Form Return */}
          <SimpleAccordion
            id="deposit-information"
            title="ข้อมูลการฝาก"
            defaultExpanded={true}
          >
            <ReturnForm />
          </SimpleAccordion>
          {/* Form Return */}

          {/* Form Payment */}
          {DepositStore.isBillRenew &&
          DepositStore.form.type !== RenewBillTypeKeyConstant.increase &&
          DepositStore.form.type !== RenewBillTypeKeyConstant.purchase ? (
            <SimpleAccordion
              id="payment-information"
              title="ข้อมูลการชำระเงิน"
              defaultExpanded={true}
            >
              <PaymentForm
                type={DepositStore.form.paymentType || ""}
                netPay={DepositStore.form.netPay || 0}
                cardPay={Number(DepositStore.form.cardPay || 0)}
                chargeCard={DepositStore.form.chargeCard || 0}
                cardPayIsError={DepositStore.formIsInvalid.cardPay}
                onTypeChange={(value: string) => {
                  onDepositInputChange("paymentType", value);
                }}
                onCardPayChange={(value: number) => {
                  onDepositInputChange("cardPay", value);
                }}
              />
            </SimpleAccordion>
          ) : null}
          {/* /.Form Payment */}

          {/* Form Customer */}
          <SimpleAccordion
            id="customer-information"
            title="ข้อมูลลูกค้า/ภาพสินค้า"
          >
            <CustomerForm
              type="DEPOSIT"
              isSoftDeleteImage={DepositStore.isBillRenew}
              isDisableEditing={
                DepositStore.form.type === RenewBillTypeKeyConstant.return ||
                DepositStore.form.type === RenewBillTypeKeyConstant.purchase ||
                DepositStore.form.type === RenewBillTypeKeyConstant.new
              }
            />
          </SimpleAccordion>
          {/* /.Form Customer */}
        </Box>

        {/* Button */}
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          marginTop={3}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Button variant="outlined" fullWidth onClick={() => onReset(true)}>
              เริ่มใหม่
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Button variant="contained-success" fullWidth onClick={onSubmit}>
              ทำรายการ
            </Button>
          </Grid>
        </Grid>
        {/* /.Button */}

        {/*  Table List */}
        <Drawer anchor={"bottom"} open={isOpenList} onClose={onCloseList}>
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
              paddingY={1}
            >
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                ประวัติการคืนล่าสุด
              </Typography>
              <CloseIcon
                sx={{
                  fontSize: 32,
                  color: StyleVariables["color-mainIcon"],
                  cursor: "pointer",
                }}
                onClick={onCloseList}
              />
            </Box>
            <Box marginTop={2}>
              <ReturnListTable
                list={deposits}
                isLoading={isLoadingList}
                noDataMessage="ไม่มีข้อมูลการคืนวันนี้"
                onClickBill={(id: string) => {
                  onOpenCompleteBillModal(id);
                }}
                onClickCancel={(deposit: DepositModel) => {}}
              />
            </Box>
          </>
        </Drawer>
        {/* /.Table List */}

        {/* Error Modal */}
        <InformationModal
          isOpen={isFailModalOpen}
          title="ทำรายการไม่สำเร็จ"
          onClose={onCloseFailModal}
        >
          <>
            <Box textAlign={"center"}>
              <Typography variant="body2" fontWeight={"regular"}>
                {messageFailModal}
              </Typography>
            </Box>
            <Button
              variant="contained"
              fullWidth
              sx={{ marginTop: 5 }}
              onClick={onCloseFailModal}
            >
              ตกลง
            </Button>
          </>
        </InformationModal>
        {/* /.Error Modal */}

        {/* Preview Return Or Purchase Bill Modal */}
        <BillModal
          isOpen={isBillModalOpen}
          title={
            DepositStore.form.type === RenewBillTypeKeyConstant.return
              ? "คืน"
              : "รับซื้อ ตัดขาย"
          }
          onSaveAndPrint={
            DepositStore.form.type !== RenewBillTypeKeyConstant.return
              ? onCreateRenewDeposit
              : onCreateReturn
          }
          onClose={() => {
            setIsBillModalOpen(false);
          }}
        >
          {DepositStore.form.type === RenewBillTypeKeyConstant.return ? (
            <ReturnBill
              deposit={DepositStore.form}
              payments={PaymentStore.list}
            />
          ) : (
            <PurchaseBill trade={bodyTradeForDepositPurchaseBill()} />
          )}
        </BillModal>
        {/* /.Preview Return Or Purchase Bill Modal */}

        {/* Preview Deposit Bill Modal */}
        <DepositBillModal
          isOpen={isDepositBillModalOpen}
          onSaveAndPrint={onCreateRenewDeposit}
          onClose={() => {
            setIsDepositBillModalOpen(false);
          }}
        >
          <DepositBill
            deposit={DepositStore.form}
            depositRenew={DepositStore.formRenew}
            customer={CustomerStore.form}
            isBillRenew={DepositStore.isBillRenew}
          />
        </DepositBillModal>
        {/* /.Preview Deposit Bill Modal */}

        {/* Preview Complete Bill Modal */}
        <BillModal
          isOpen={isCompleteBillModalOpen}
          title={
            deposit?.type !== RenewBillTypeKeyConstant.purchase
              ? generateReturnTitleBill(deposit?.type || "", true)
              : "คืน ตัดขาย (สำเนา)"
          }
          isComplete={true}
          onPrint={() =>
            onPrintBillPdfHistory(deposit?.returnReceiptCopy?.id || "")
          }
          onClose={onCloseCompleteBillModal}
        >
          <ReturnBill deposit={deposit} payments={payments} />
        </BillModal>
        {/* /.Preview Complete Bill Modal */}

        <LoadProgressing isOpen={isLoadProgressing} />

        <AlertSnackbar
          type="success"
          isOpen={isOpenSuccessSnackbar}
          description="ทำรายการคืนสำเร็จ"
          onClose={() => setIsOpenSuccessSnackbar(false)}
        />
      </>
    </MainLayout>
  );
});

export default Return;
