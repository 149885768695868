import { MaximumPercentGold as MaximumPercentGoldConstant } from "../constants/Purchase";
import {
  PriceRoundUp as PriceRoundUpUtil,
  PriceRoundDown as PriceRoundDownUtil,
} from "./NumberRounding";
import { RangeOfInterestStatusForCalculate as RangeOfInterestStatusForCalculateDepositConstant } from "../constants/Deposit";

// คำนวณราคาเนื้อทองมาตรฐาน
const calculateGoldPriceStandard = (
  goldSellPriceNow: number,
  percentGold: number,
  isGoldBar: boolean,
  qty: number,
  weight: number
) => {
  const weightForDivide = !isGoldBar ? 15.16 : 15.244;
  const goldPrice =
    (goldSellPriceNow / weightForDivide) * (percentGold / 100) * weight * qty; // ((ราคาขายปัจจุบัน / ค่าคงที่น้ำหนักทอง) * (เปอร์เซ็นต์ทอง / 100) * น้ำหนักทอง) * จำนวน
  // const finalGoldPrice = PriceRoundUpUtil(goldPrice);
  return goldPrice;
};

// คำนวณราคาขายมาตรฐาน
const calculateSellPriceStandard = (
  goldPriceStandard: number,
  labourStandard: number,
  qty: number,
  isNotRoundUp?: boolean
) => {
  const sellPriceStandard = (goldPriceStandard + labourStandard) * qty; // (ราคาเนื้อทองมาตรฐาน + ค่ากำเหน็จมาตรฐาน) * จำนวน
  const finalSellPriceStandard = !isNotRoundUp
    ? PriceRoundUpUtil(sellPriceStandard)
    : sellPriceStandard;
  return finalSellPriceStandard;
};

//  คำนวณราคาขายออกเงินสด
const calculateSellPrice = (goldPrice: number, labour: number) => {
  const sellPrice = goldPrice + labour; //ราคาเนื้อทอง + ค่ากำเหน็จ
  return sellPrice;
};

// คำนวณตรวจสอบราคาขาย
const currentStatusSellPrice = (
  totalLabour: number, // ค่ากำเหน็จรวม
  totalLabourStandardExcellent: number, // 1.5 เท่าของค่ากำเหน็จมาตรฐานรวม
  totalLabourStandardGood: number, // ค่าเหน็จมาตรฐานรวม
  totalLabourStandardFair: number // 0.5 เท่าของค่ากำเหน็จมาตรฐานรวม
) => {
  if (totalLabour <= 0) {
    return "poor";
  }
  if (
    totalLabour >= totalLabourStandardGood &&
    totalLabour <= totalLabourStandardExcellent
  ) {
    return "good";
  }
  if (totalLabour > totalLabourStandardExcellent) {
    return "excellent";
  }
  if (
    totalLabour < totalLabourStandardGood &&
    totalLabour >= totalLabourStandardFair
  ) {
    return "fair";
  }
  return "poor";
};

// คำนวณราคาส่วนลดการขาย
const calculateSellDiscount = (
  totalSellPriceStandard: number, // ราคาขายมาตรฐานรวม
  totalSellPrice: number, // ราคาขายรวม,
  qty: number // จำนวน
) => {
  const discount = (totalSellPriceStandard - totalSellPrice) / qty;
  return Math.round(discount * 100) / 100;
};

// คำนวณค่ากำเหน็จ โดยเฉลี่ยส่วนลด
const calculateLabourByAverageDiscount = (
  totalDiscount: number,
  labourStandard: number,
  totalLabourStandard: number,
  totalWeight: number,
  weight: number
) => {
  let percentLabour = 0;
  let percentWeight = 0;
  let labourDiscount = 0;
  let finalLabour = 0;
  if (totalLabourStandard > 0) {
    percentLabour = (labourStandard * 100) / totalLabourStandard;
    labourDiscount = (totalDiscount * percentLabour) / 100;
  } else {
    percentWeight = (weight * 100) / totalWeight;
    labourDiscount = (totalDiscount * percentWeight) / 100;
  }
  finalLabour = labourStandard - labourDiscount;
  return Number(finalLabour.toFixed(2));
};

// คำนวณยอดเผื่อต่อการขาย
const calculateSellNegotiation = (labourStandard: number) => {
  const priceNegotiation = PriceRoundDownUtil(labourStandard * 0.1);
  const finalPriceNegotiation =
    priceNegotiation >= 100 ? priceNegotiation : 100;
  return finalPriceNegotiation;
};

// คำนวณราคารับซื้อมาตรฐาน
const calculatePurchasePriceStandard = (
  goldPurchasePriceNow: number, // ราคารับซื้อปัจจุบัน
  percentGold: number, // เปอร์เซ็นต์ทอง
  weight: number // น้ำหนักทอง
) => {
  const finalPercentGold =
    percentGold <= 100 ? percentGold : MaximumPercentGoldConstant;
  const purchaseStandard =
    (goldPurchasePriceNow / 15.244) * (finalPercentGold / 100) * weight; // (ราคารับซื้อปัจจุบัน / 15.244) * (เปอร์เซ็นต์ทอง / 100) * น้ำหนักทอง
  return purchaseStandard;
};

// คำนวณตรวจสอบราคารับซื้อ
const currentStatusPurchasePrice = (
  price: number, // ราคารับซื้อ
  priceStandardExcellent: number, // ราคารับซื้อมาตรฐาน (excellent)
  priceStandardGood: number, // ราคารับซื้อมาตรฐาน (good)
  priceStandardFair: number, // ราคารับซื้อมาตรฐาน (fair)
  priceStandardPoor: number // ราคารับซื้อมาตรฐาน (poor)
) => {
  if (price <= priceStandardGood && price >= priceStandardExcellent) {
    return "good";
  } else if (price < priceStandardExcellent) {
    return "excellent";
  } else if (price > priceStandardGood && price <= priceStandardFair) {
    return "fair";
  } else {
    return "poor";
  }
};

// คำนวณเปอร์เซ็นต์ทองรับซื้อ
const calculatePurchasePercentGold = (
  goldPurchasePriceNow: number, // ราคารับซื้อปัจจุบัน
  totalPay: number, // ราคารับซื้อ
  weight: number // น้ำหนักทอง
) => {
  const purchasePercentGold =
    totalPay * (15.244 / goldPurchasePriceNow) * (1 / weight);
  return (purchasePercentGold * 100).toFixed(2);
};

// คำนวณยอดเผื่อต่อการรับซื้อ
const calculatePurchaseNegotiation = (
  goldPurchasePriceNow: number,
  percentGold: number,
  weight: number
) => {
  const goldPurchasePricePerGram = goldPurchasePriceNow / 15.244;
  const percentNegotiation = percentGold >= 80 ? 0.005 : 0.025;
  const priceNegotiation = PriceRoundDownUtil(
    goldPurchasePricePerGram * percentNegotiation * weight
  );
  const finalPriceNegotiation =
    priceNegotiation >= 100 ? priceNegotiation : 100;
  return finalPriceNegotiation;
};

// คำนวณราคารับฝากมาตรฐาน
const calculateDepositPriceStandard = (
  goldPurchasePriceNow: number, // ราคารับซื้อปัจจุบัน
  percentGold: number, // เปอร์เซ็นต์ทอง
  weight: number, // นำ้หนักทอง
  period: number // ระยะเวลา (เดือน)
) => {
  const purchasePriceStandard = calculatePurchasePriceStandard(
    goldPurchasePriceNow,
    percentGold,
    weight
  );
  const finalPurchasePriceStandard = PriceRoundDownUtil(purchasePriceStandard);
  const depositPriceStandard =
    finalPurchasePriceStandard * ((100 - period - 1) / 100);
  return Math.floor(depositPriceStandard / 100) * 100;
};

// คำนวณจำนวนวงเงินสูงสุดในการเพิ่มยอดการฝาก ต่อบิล
const calculateMaxIncreaseDepositPrice = (
  goldPurchasePriceNow: number, // ราคารับซื้อปัจจุบัน
  percentGold: number, // เปอร์เซ็นต์ทอง
  weight: number, // นำ้หนักทอง
  period: number, // ระยะเวลา (เดือน)
  oldDepositPriceStandard: number, // ราคารับฝากมาตรฐานเดิม
  oldDepositPrice: number // ราคารับฝากเดิม
) => {
  const purchasePriceStandard = calculateDepositPriceStandard(
    goldPurchasePriceNow,
    percentGold,
    weight,
    period
  );
  const standard =
    oldDepositPriceStandard > purchasePriceStandard
      ? oldDepositPriceStandard
      : purchasePriceStandard;
  const maxIncreaseDepositPrice = standard - oldDepositPrice;
  return maxIncreaseDepositPrice;
};

// คำนวณตรวจสอบราคาดอกเบี้ย
const currentInterestStatusDeposit = (
  interest: number,
  interestStandard: number,
  depositPrice: number,
  percentInterestStandard: number
) => {
  const interestStandardFair =
    depositPrice *
    (percentInterestStandard / 100 +
      RangeOfInterestStatusForCalculateDepositConstant.fair);
  if (interest === interestStandard) {
    return "good";
  }
  if (interest > interestStandard) {
    return "excellent";
  }
  if (interest <= 0) {
    return "poor";
  }
  if (interest < interestStandard && interest >= interestStandardFair) {
    return "fair";
  }
  return "poor";
};

// คำนวณเปอร์เซ็นต์ทองทองเก่ารับซื้อสำหรับการเปลี่ยน
const calculatePercentGoldPurchasePriceForExchange = (
  percentGold: number,
  weight: number
) => {
  let newPercentGold = percentGold;
  switch (true) {
    case weight <= 1.5:
      newPercentGold += 1;
      break;
    case weight <= 2.8:
      newPercentGold += 1.5;
      break;
    case weight <= 5.6:
      newPercentGold += 2;
      break;
    case weight > 5.6:
      newPercentGold += 2.5;
      break;
    default:
      break;
  }
  return newPercentGold;
};

// คำนวณราคารับซื้อมาตรฐานกรณีทองเก่าแลกทองใหม่
const calculatePurchasePriceStandardForExchange = (
  goldPurchasePriceNow: number, // ราคารับซื้อ ณ ปัจจุบัน
  percentGoldPurchasePriceForExchange: number, // เปอร์เซ็นต์ทองรับซื้อสำหรับการเปลี่ยน
  weight: number // น้ำหนักทองเก่า
) => {
  let purchasePrice = 0;
  purchasePrice = calculatePurchasePriceStandard(
    goldPurchasePriceNow,
    percentGoldPurchasePriceForExchange,
    weight
  );
  return PriceRoundDownUtil(purchasePrice);
};

// คำนวณน้ำหนักเพื่อหาส่วนลดค่ากำเหน็จพิเศษ
const calculateWeightForExtraDiscountSellPriceToExchange = (weight: number) => {
  const weightForExtraDiscountSelPriceToExchange = weight + (weight * 20) / 100;
  return weightForExtraDiscountSelPriceToExchange;
};

// คำนวณส่วนลดค่ากำเหน็จพิเศษ
const calculateExtraDiscountSellPriceForExchange = (
  labourStandardNewByTotalOldWeight: number, // คำเหน็จมาตรฐานเทียบเท่ากับน้ำหนักทองเก่า
  oldTotalWeight: number // น้ำหนักรวมทองเก่า
) => {
  const discountByTotalOldWeight =
    (labourStandardNewByTotalOldWeight * 20) / 100;
  const ratioOld = (oldTotalWeight * 100) / 15.244;
  return parseFloat((discountByTotalOldWeight + ratioOld).toFixed(2));
};

const calculateExchangePriceStandard = (
  sellPriceAfterDiscount: number, // ราคาขายหลังหักส่วนลดพิเศษ
  purchasePrice: number // ราคารับซื้อทองเก่า
) => {
  const exchangePrice = sellPriceAfterDiscount - purchasePrice;
  const finalExchangePrice = PriceRoundUpUtil(exchangePrice);
  return finalExchangePrice;
};
export {
  calculateGoldPriceStandard as CalculateGoldPriceStandard,
  calculateSellPriceStandard as CalculateSellPriceStandard,
  calculateSellPrice as CalculateSellPrice,
  currentStatusSellPrice as CurrentStatusSellPrice,
  calculateSellDiscount as CalculateSellDiscount,
  calculateLabourByAverageDiscount as CalculateLabourByAverageDiscount,
  calculateSellNegotiation as CalculateSellNegotiation,
  calculatePurchasePriceStandard as CalculatePurchasePriceStandard,
  currentStatusPurchasePrice as CurrentStatusPurchasePrice,
  calculatePurchasePercentGold as CalculatePurchasePercentGold,
  calculatePurchaseNegotiation as CalculatePurchaseNegotiation,
  calculateDepositPriceStandard as CalculateDepositPriceStandard,
  calculateMaxIncreaseDepositPrice as CalculateMaxIncreaseDepositPrice,
  currentInterestStatusDeposit as CurrentInterestStatusDeposit,
  calculatePercentGoldPurchasePriceForExchange as CalculatePercentGoldPurchasePriceForExchange,
  calculatePurchasePriceStandardForExchange as CalculatePurchasePriceStandardForExchange,
  calculateWeightForExtraDiscountSellPriceToExchange as CalculateWeightForExtraDiscountSellPriceToExchange,
  calculateExtraDiscountSellPriceForExchange as CalculateExtraDiscountSellPriceForExchange,
  calculateExchangePriceStandard as CalculateExchangePriceStandard,
};
