import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import {
  DateBuddhistEra as DateBuddhistEraUtil,
  DateTimeBuddhistEra as DateTimeBuddhistEraUtil,
} from "../../../utils/DateFormat";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import { ProductWeightType as ProductWeightTypeConstant } from "../../../constants/Gold";
import {
  Deposit as DepositModel,
  DepositRenew as DepositRenewModel,
} from "../../../models/Deposit";

type Props = {
  isBillRenew: boolean;
  deposit: DepositModel | null;
  depositRenew: DepositRenewModel | null;
};

const DepositCancel = observer((props: Props) => {
  return (
    <Box color={StyleVariables["color-text"]}>
      {/* Heading */}
      <Box borderBottom={`1px dashed ${StyleVariables["color-border"]}`}>
        {/* Branch Name && Ref Invoice Number */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={1}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.branchName}
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {!props.isBillRenew
              ? `${
                  props.deposit?.refInvoiceNo
                    ? `${props.deposit?.refInvoiceNo?.split("-")[1]}-${
                        props.deposit?.refInvoiceNo?.split("-")[2]
                      }`
                    : "XX-XXXX"
                }`
              : `${
                  props.depositRenew?.refInvoiceNo
                    ? `${props.depositRenew?.refInvoiceNo?.split("-")[1]}-${
                        props.depositRenew?.refInvoiceNo?.split("-")[2]
                      }`
                    : "XX-XXXX"
                }`}
          </Typography>
        </Box>
        {/* /.Branch Name && Ref Invoice Number */}

        {/* Employee Name && Cancel Date */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={2}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.cancelByUserFirstName}{" "}
            {props.deposit?.cancelByUserLastName &&
              props.deposit?.cancelByUserLastName[0]}
            .
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.cancelAt
              ? DateTimeBuddhistEraUtil(props.deposit?.cancelAt)
              : `${DateBuddhistEraUtil(dayjs())} --:--`}
          </Typography>
        </Box>
        {/* /.Employee Name && Cancel Date */}
      </Box>
      {/* /.Heading */}

      {/* Product List && Reason */}
      <Box marginY={2}>
        <Grid container columnSpacing={1} alignItems={"flex-start"}>
          <Grid item xs={7}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              {props.deposit?.productName} จำนวน {props.deposit?.totalQuantity}{" "}
              ชิ้น
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              marginTop={0.5}
            >
              {`นน.รวม ${props.deposit?.totalWeight} กรัม`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            textAlign={"right"}
            sx={{ wordBreak: "break-word" }}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              <NumericFormat
                value={
                  props.isBillRenew
                    ? props.depositRenew?.newDepositPrice
                    : props.deposit?.depositPrice
                }
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="subtitle2"
          fontWeight={"regular"}
          marginTop={2}
          paddingBottom={2}
          textAlign={"center"}
          whiteSpace={"break-spaces"}
          borderBottom={`1px dashed ${StyleVariables["color-border"]}`}
        >
          {"ยกเลิก  ยกเลิก  ยกเลิก  ยกเลิก  ยกเลิก"}
        </Typography>
        <Typography variant="subtitle2" fontWeight={"regular"} marginTop={2}>
          เหตุผล:{" "}
          {`${props.deposit?.cancelReason?.reason} ${
            props.deposit?.cancelMisc || ""
          }`}
        </Typography>
      </Box>
      {/* /.Product List && Reason */}
    </Box>
  );
});

export default DepositCancel;
