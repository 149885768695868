import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import dayjs from "dayjs";
import {
  DateTimeBuddhistEra as DateTimeBuddhistEraUtil,
  DateBuddhistEra as DateBuddhistEraUtil,
} from "../../../utils/DateFormat";
import { Trade as TradeModel } from "../../../models/Trade";
import { NumericFormat } from "react-number-format";

type Props = {
  trade: TradeModel | null;
};

const PurchaseCancel = (props: Props) => {
  return (
    <Box color={StyleVariables["color-text"]}>
      {/* Heading */}
      <Box borderBottom={`1px dashed ${StyleVariables["color-border"]}`}>
        {/* Branch Name && Ref Invoice Number */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={1}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.branchName}
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.refInvoiceNo || "X-XXXXXXXX-XXXX"}
          </Typography>
        </Box>
        {/* /.Branch Name && Ref Invoice Number */}

        {/* Cancel Date && Employee Name */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={2}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.cancelByUserFirstName}{" "}
            {props.trade?.cancelByUserLastName &&
              props.trade?.cancelByUserLastName[0]}
            .
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.cancelAt
              ? DateTimeBuddhistEraUtil(props.trade?.cancelAt)
              : `${DateBuddhistEraUtil(dayjs())} --:--`}
          </Typography>
        </Box>
        {/* /.Cancel Date && Employee Name */}
      </Box>
      {/* /.Heading */}

      {/* Product List && Reason */}
      <Box marginY={2}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={7}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              {props.trade?.productName} จำนวน {props.trade?.totalQuantity} ชิ้น
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              marginTop={1}
            >
              {`(นน.รวม${props.trade?.totalWeight} กรัม)`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            textAlign={"right"}
            sx={{ wordBreak: "break-word" }}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              <NumericFormat
                value={props.trade?.totalPay}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="subtitle2"
          fontWeight={"regular"}
          marginTop={2}
          paddingBottom={2}
          textAlign={"center"}
          whiteSpace={"break-spaces"}
          borderBottom={`1px dashed ${StyleVariables["color-border"]}`}
        >
          {"ยกเลิก  ยกเลิก  ยกเลิก  ยกเลิก  ยกเลิก"}
        </Typography>
        <Typography variant="subtitle2" fontWeight={"regular"} marginTop={2}>
          เหตุผล:{" "}
          {`${props.trade?.cancelReason?.reason} ${
            props.trade?.cancelMisc || ""
          }`}
        </Typography>
      </Box>
      {/* /.Product List && Reason */}
    </Box>
  );
};

export default PurchaseCancel;
