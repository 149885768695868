import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import {
  DateBuddhistEra as DateBuddhistEraUtil,
  DateTimeBuddhistEra as DateTimeBuddhistEraUtil,
} from "../../../utils/DateFormat";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import { ProductWeightType as ProductWeightTypeConstant } from "../../../constants/Gold";
import { Exchange as ExchangeModel } from "../../../models/Exchange";

type Props = {
  exchange: ExchangeModel | null;
};

const ExchangeCancel = observer((props: Props) => {
  return (
    <Box color={StyleVariables["color-text"]}>
      {/* Heading */}
      <Box borderBottom={`1px dashed ${StyleVariables["color-border"]}`}>
        {/* Branch Name && Ref Invoice Number */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={1}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.exchange?.branchName}
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.exchange?.refInvoiceNo || "X-XXXXXXXX-XXXX"}
          </Typography>
        </Box>
        {/* /.Branch Name && Ref Invoice Number */}

        {/* Create Date && Employee Name */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={2}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.exchange?.cancelByUserFirstName}{" "}
            {props.exchange?.cancelByUserLastName &&
              props.exchange?.cancelByUserLastName[0]}
            .
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.exchange?.cancelAt
              ? DateTimeBuddhistEraUtil(props.exchange?.cancelAt)
              : `${DateBuddhistEraUtil(dayjs())} --:--`}
          </Typography>
        </Box>
        {/* /.Create Date && Employee Name */}
      </Box>
      {/* /.Heading */}

      {/* Product List && Reason */}
      <Box marginTop={2}>
        {/* Old */}
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={7}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              ทองเก่า: {props.exchange?.exchangeOldHasItems[0]?.productName}{" "}
              จำนวน {props.exchange?.oldTotalQuantity} ชิ้น
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              marginTop={1}
            >
              {`(นน.รวม${parseFloat(
                props.exchange?.oldTotalWeight.toFixed(3) || "0"
              )} กรัม)`}
            </Typography>
          </Grid>
        </Grid>
        {/* /.Old */}

        {/* New */}
        <Grid container spacing={1} alignItems={"center"} marginTop={1}>
          <Grid item xs={7}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              ทองใหม่: {props.exchange?.exchangeNewHasItems[0]?.productName}{" "}
              จำนวน {props.exchange?.newTotalQuantity} ชิ้น
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              marginTop={1}
            >
              {props.exchange?.exchangeNewHasItems[0]?.productType ===
              ProductWeightTypeConstant.equalWeight
                ? `(นน.${parseFloat(
                    props.exchange?.exchangeNewHasItems[0]?.weight.toFixed(3)
                  )} กรัม/ชิ้น)`
                : `(นน.รวม${parseFloat(
                    props.exchange?.newTotalWeight.toFixed(3) || "0"
                  )} กรัม)`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            textAlign={"right"}
            sx={{ wordBreak: "break-word" }}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              <NumericFormat
                value={props.exchange?.netPay}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Grid>
        </Grid>
        {/* /.New */}

        <Typography
          variant="subtitle2"
          fontWeight={"regular"}
          marginTop={2}
          paddingBottom={2}
          textAlign={"center"}
          whiteSpace={"break-spaces"}
          borderBottom={`1px dashed ${StyleVariables["color-border"]}`}
        >
          {"ยกเลิก  ยกเลิก  ยกเลิก  ยกเลิก  ยกเลิก"}
        </Typography>
        <Typography variant="subtitle2" fontWeight={"regular"} marginTop={2}>
          เหตุผล:{" "}
          {`${props.exchange?.cancelReason?.reason} ${
            props.exchange?.cancelMisc || ""
          }`}
        </Typography>
      </Box>
      {/* /.Product List && Reason */}
    </Box>
  );
});

export default ExchangeCancel;
