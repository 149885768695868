import React from "react";
import { Box, Typography, Modal, Fade, Button } from "@mui/material";
import { CloseRounded as CloseRoundedIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

interface Props {
  isOpen: boolean;
  children: JSX.Element;
  isComplete?: boolean;
  isCancel?: boolean;
  onSaveAndPrint?: () => void;
  onPrint?: () => void;
  onClose: () => void;
}

const Bill = (props: Props) => {
  return (
    <Modal
      className="modal modal-deposit-receipt"
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={props.isOpen}>
        <Box
          className="modal-body"
          sx={{
            padding: `${Number(StyleVariables.spacing) * 3}px !important`,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            onClick={props.onClose}
          >
            <CloseRoundedIcon
              sx={{
                color: StyleVariables["color-main-icon"],
                fontSize: 32,
                cursor: "pointer",
              }}
            />
          </Box>
          <Box>{props.children}</Box>
          <Box marginTop={5} marginBottom={3}>
            {/* Button */}
            {!props.isCancel ? (
              !props.isComplete ? (
                <Box>
                  <Button
                    variant="contained-success"
                    fullWidth
                    onClick={props.onSaveAndPrint}
                  >
                    บันทึกและพิมพ์รายการ
                  </Button>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 2 }}
                    onClick={props.onClose}
                  >
                    แก้ไขข้อมูล
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="contained-success"
                  fullWidth
                  onClick={props.onPrint}
                >
                  พิมพ์ใบรายการ
                </Button>
              )
            ) : null}
            {/* /.Button */}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Bill;
