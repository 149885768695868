import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { Box, Grid, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import RadioSelectionInput from "../inputs/RadioSelection";
import NumberInput from "../inputs/Number";
import CashOrQrCodePaymentTable from "../tables/payments/CashOrQrCode";
import CreditOrMixPaymentTable from "../tables/payments/CreditOrMix";
import { KeysType as KeysTypeConstant } from "../../constants/Payment";
import { NumericFormat } from "react-number-format";

type Props = {
  type: string;
  netPay: number;
  cardPay: number;
  chargeCard: number;
  cardPayIsError: boolean;
  onTypeChange: (value: string) => void;
  onCardPayChange: (value: number) => void;
};

const Payment = observer((props: Props) => {
  const { PaymentStore } = useStores();

  useEffect(() => {
    PaymentStore.getChannels();

    return () => {
      PaymentStore.onClearStore(0);
    };
  }, [PaymentStore]);

  const onInputChargeCardChange = (value: number) => {
    props.onCardPayChange(value);
  };

  const getBalance = () => {
    const totalAmountFromList = PaymentStore.list.reduce(
      (n, { amount }) => n + amount,
      0
    );
    return props.netPay - totalAmountFromList;
  };

  return (
    <>
      <Typography
        variant="h6"
        fontWeight={"bold"}
        color={StyleVariables["color-header"]}
      >
        เลือกวิธีการชำระเงิน
      </Typography>
      <Box display={"flex"} flexWrap={"wrap"} marginY={4}>
        {PaymentStore.types.map((type, index) => {
          return (
            <Box key={index} marginRight={3}>
              <RadioSelectionInput
                name="paymentType"
                label={type.label}
                selectedValue={props.type}
                value={type.value}
                isDisabled={props.netPay < 0}
                onChange={(value: any) => {
                  props.onTypeChange(value);
                }}
              />
            </Box>
          );
        })}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <NumberInput
            name="cardPay"
            label="ยอดชำระด้วยบัตรเครดิต (ไม่รวม Charge)"
            placeholder="กรอกยอดชำระด้วยบัตรเครดิต"
            value={`${props.cardPay || ""}`}
            decimalScale={0}
            maxLength={`${props.netPay}`.length}
            isDisabled={props.type !== KeysTypeConstant.mix}
            isError={
              (props.cardPayIsError || PaymentStore.cardPayIsInvalid) &&
              props.type === KeysTypeConstant.mix
            }
            handleOnChange={(value: any) => {
              onInputChargeCardChange(Number(value));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} alignSelf={"flex-end"} position={"relative"}>
          <NumberInput
            name="netPay"
            label="รับเงินจากลูกค้า"
            placeholder={
              props.netPay >= 0 ? "กรอกอัตโนมัติ" : "ไม่สามารถทำรายการได้"
            }
            value={props.netPay >= 0 ? `${props.netPay || "0"}` : ""}
            isDisabled={true}
            isError={props.netPay < 0}
            inputPropsClassName="input-highlight-secondary"
            subLabel={
              props.chargeCard ? (
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  color={StyleVariables["color-error"]}
                >
                  {"(ชาร์จ "}
                  <NumericFormat
                    value={props.chargeCard}
                    displayType="text"
                    thousandSeparator
                  />
                  {")"}
                </Typography>
              ) : undefined
            }
            handleOnChange={(value: any) => {}}
          />
        </Grid>
      </Grid>
      <Box display={"flex"} marginY={4} gap={1}>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          color={StyleVariables["color-header"]}
        >
          บันทึกการชำระเงิน
        </Typography>
        {props.type === KeysTypeConstant.allCreditCard && getBalance() > 0 ? (
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={StyleVariables["color-error"]}
          >
            {`(ขาดอีก `}
            <NumericFormat
              value={getBalance()}
              displayType="text"
              thousandSeparator
            />
            {`)`}
          </Typography>
        ) : null}
      </Box>
      {props.type === KeysTypeConstant.allCash ||
      props.type === KeysTypeConstant.allQrCode ||
      props.type === KeysTypeConstant.allTransfer ? (
        <CashOrQrCodePaymentTable type={props.type} netPay={props.netPay} />
      ) : (
        <CreditOrMixPaymentTable type={props.type} netPay={props.netPay} />
      )}
    </>
  );
});

export default Payment;
