import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  DateBuddhistEra as DateBuddhistEraUtil,
  DateTimeBuddhistEra as DateTimeBuddhistEraUtil,
} from "../../utils/DateFormat";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import { KeysType as KeysTypeConstant } from "../../constants/Payment";
import { ProductWeightType as ProductWeightTypeConstant } from "../../constants/Gold";
import { Trade as TradeModel } from "../../models/Trade";
import { Payment as PaymentModel } from "../../models/Payment";

type Props = {
  trade: TradeModel | null;
  payments: PaymentModel[];
};

const Sell = observer((props: Props) => {
  const [payments, setPayments] = useState<any[]>([]);

  useEffect(() => {
    let mappingPayment = [];
    let totalCash =
      props.trade?.paymentType === KeysTypeConstant.allCash ||
      props.trade?.paymentType === KeysTypeConstant.mix
        ? props.payments.find((item) => item.channelCode === "99")?.amount || 0
        : 0;
    let totalTransfer = props.payments
      ?.filter(
        (item) => item.channelName.toLocaleLowerCase().search("ลูกค้าโอน") >= 0
      )
      .reduce((n, { amount }) => n + amount, 0);
    let totalQrCode = props.payments
      ?.filter((item) => item.channelName.toLocaleLowerCase().search("qr") >= 0)
      .reduce((n, { amount }) => n + amount, 0);
    let totalCreditCard = props.payments
      ?.filter(
        (item, index) =>
          item.channelName.toLocaleLowerCase().search("qr") < 0 &&
          item.channelName.toLocaleLowerCase().search("ลูกค้าโอน") < 0 &&
          item.channelName.toLocaleLowerCase().search("เฟิร์สช้อยส์") < 0 &&
          item.channelName.toLocaleLowerCase().search("อิออน") < 0 &&
          item.channelCode !== "99"
      )
      .reduce((n, { amount }) => n + amount, 0);
    let totalFirstChoice = props.payments
      ?.filter(
        (item) =>
          item.channelName.toLocaleLowerCase().search("เฟิร์สช้อยส์") >= 0 ||
          item.channelName.toLocaleLowerCase().search("อิออน") >= 0
      )
      .reduce((n, { amount }) => n + amount, 0);

    totalCash > 0 &&
      mappingPayment.push({ channelName: "เงินสด", amount: totalCash });
    totalQrCode > 0 &&
      mappingPayment.push({ channelName: "QR Code", amount: totalQrCode });
    totalTransfer > 0 &&
      mappingPayment.push({ channelName: "เงินโอน", amount: totalTransfer });
    totalCreditCard > 0 &&
      mappingPayment.push({
        channelName: "บัตรเครดิต",
        amount: totalCreditCard,
      });
    totalFirstChoice > 0 &&
      mappingPayment.push({
        channelName: "เฟิร์สช้อยส์/อิออน",
        amount: totalFirstChoice,
      });
    setPayments([...mappingPayment]);
  }, [props.payments, props.trade?.paymentType]);

  return (
    <Box color={StyleVariables["color-text"]}>
      {/* Heading */}
      <Box borderBottom={`1px dashed ${StyleVariables["color-border"]}`}>
        {/* Branch Name && Ref Invoice Number */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={1}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.branchName}
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.refInvoiceNo || "X-XXXXXXXX-XXXX"}
          </Typography>
        </Box>
        {/* /.Branch Name && Ref Invoice Number */}

        {/* Employee Name && Create Date */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={2}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.userFirstName}{" "}
            {props.trade?.userLastName && props.trade?.userLastName[0]}.
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.trade?.createdAt
              ? DateTimeBuddhistEraUtil(props.trade?.createdAt)
              : `${DateBuddhistEraUtil(dayjs())} --:--`}
          </Typography>
        </Box>
        {/* /.Employee Name && Create Date */}
      </Box>
      {/* /.Heading */}

      {/* Product List */}
      <Box marginY={2}>
        <Grid container spacing={0.5} alignItems={"center"}>
          <Grid item xs={7}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              {props.trade?.productName} จำนวน {props.trade?.totalQuantity} ชิ้น
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              marginTop={1}
            >
              {props.trade?.productType ===
              ProductWeightTypeConstant.equalWeight
                ? `(นน.${parseFloat(
                    props.trade?.tradeHasItems[0]?.weight.toFixed(3)
                  )} กรัม/ชิ้น)`
                : `(นน.รวม${parseFloat(
                    props.trade?.totalWeight.toFixed(3) || "0"
                  )} กรัม)`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            textAlign={"right"}
            sx={{ wordBreak: "break-word" }}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              <NumericFormat
                value={props.trade?.netPay}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* /.Product List */}

      {/* Payment Detail */}
      <Box
        marginY={2}
        paddingBottom={2}
        borderBottom={`1px dashed ${StyleVariables["color-border"]}`}
      >
        <Typography variant="subtitle2" fontWeight={"regular"}>
          รับเงินจากลูกค้าโดย
        </Typography>
        {payments.map((item, index) => {
          return (
            <Grid key={index} container marginTop={1}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" fontWeight={"regular"}>
                  {item.channelName}
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign={"right"}>
                <Typography variant="subtitle2" fontWeight={"regular"}>
                  <NumericFormat
                    value={item.amount}
                    displayType="text"
                    thousandSeparator
                  />
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Box>
      {/* Payment Detail */}

      {/* Detail for Staff */}
      <Box marginTop={2} color={StyleVariables["color-placeholder"]}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          รายละเอียดต่างๆ
        </Typography>
        {/* Current Sell - Purchase Price */}
        <Typography variant="subtitle2" fontWeight={"regular"} marginTop={1}>
          <NumericFormat
            value={props.trade?.goldSellPrice}
            displayType="text"
            thousandSeparator
          />{" "}
          -{" "}
          <NumericFormat
            value={props.trade?.goldPurchasePrice}
            displayType="text"
            thousandSeparator
          />
        </Typography>
        {/* /.Current Sell - Purchase Price */}

        {/* Gold Price */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginTop={1}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            ราคาเนื้อทอง
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight={"regular"}
            textAlign={"right"}
          >
            <NumericFormat
              value={props.trade?.totalGoldPrice}
              displayType="text"
              decimalScale={2}
              thousandSeparator
            />
          </Typography>
        </Box>
        {/* /.Gold Price */}

        {/* Labour */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginTop={1}
          color={
            props.trade?.currentStatus !== "poor"
              ? StyleVariables["color-placeholder"]
              : StyleVariables["color-error"]
          }
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            ค่ากำเหน็จ
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight={"regular"}
            textAlign={"right"}
          >
            <NumericFormat
              value={props.trade?.totalLabour}
              displayType="text"
              decimalScale={2}
              thousandSeparator
            />
          </Typography>
        </Box>
        {/* /.Labour */}
      </Box>
      {props.trade?.currentStatus === "poor" && (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={1}
            marginTop={1}
            color={StyleVariables["color-error"]}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              ค่ากำเหน็จมาตรฐาน
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              textAlign={"right"}
            >
              <NumericFormat
                value={props.trade?.totalLabourStandard}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            fontWeight={"medium"}
            color={StyleVariables["color-error"]}
            marginTop={2}
          >
            กรุณาตรวจสอบราคาอีกครั้ง
          </Typography>
        </>
      )}
      {/* /.Detail for Staff */}
    </Box>
  );
});

export default Sell;
