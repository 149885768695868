import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  ReceiptLongRounded as ReceiptLongRoundedIcon,
  CancelOutlined as CancelOutlinedIcon,
} from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { NumericFormat } from "react-number-format";
import { RefInvoice as RefInvoiceConstant } from "../../constants/Gold";
import { RenewBillTypeKey as RenewBillTypeKeyConstant } from "../../constants/Deposit";

type Props = {
  list: any[];
  isLoading?: boolean;
  noDataMessage: string;
  onClickBill: (id: string) => void;
  onClickCancel: (trade: any) => void;
};

const DepositList = (props: Props) => {
  const columns: {
    align: "left" | "right" | "center";
    minWidth: number;
    width: string;
    name: string;
    sticky?: boolean;
    stickyPosition?: 0;
  }[] = [
    {
      align: "center",
      minWidth: 100,
      width: "10%",
      name: "เลขที่บิล",
    },
    {
      align: "center",
      minWidth: 180,
      width: "15%",
      name: "ชื่อสินค้า",
    },
    {
      align: "center",
      minWidth: 60,
      width: "5%",
      name: "จำนวน",
    },
    {
      align: "center",
      minWidth: 120,
      width: "15%",
      name: "น้ำหนัก",
    },
    {
      align: "center",
      minWidth: 180,
      width: "20%",
      name: "ราคา",
    },
    {
      align: "center",
      minWidth: 100,
      width: "10%",
      name: "ดอกเบี้ย",
    },
    {
      align: "center",
      minWidth: 60,
      width: "5%",
      name: "เดือน",
    },
    {
      align: "center",
      minWidth: 100,
      width: "10%",
      name: "ต่อบิล",
    },
    {
      align: "center",
      minWidth: 150,
      width: "10%",
      name: "Action",
      sticky: true,
      stickyPosition: 0,
    },
  ];
  return (
    <>
      <TableContainer sx={{ maxHeight: 240, overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, columnIndex) => {
                return (
                  <TableCell
                    key={columnIndex}
                    sx={{
                      minWidth: column.minWidth,
                      width: column.width,
                      paddingY: 3,
                      paddingX: 1,
                      bgcolor: StyleVariables["color-background"],
                      ...(column.sticky && {
                        position: "sticky",
                        right: column.stickyPosition,
                        zIndex: 10,
                        "@media (max-width:1225px)": {
                          boxShadow: StyleVariables["shadow-table-sticky"],
                        },
                      }),
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      color={StyleVariables["color-black"]}
                      textAlign={column.align}
                    >
                      {column.name}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {!props.isLoading && props.list?.length ? (
            <TableBody>
              {props.list.map((deposit, index) => {
                return (
                  <TableRow key={index}>
                    {/* Bill No */}
                    <TableCell
                      sx={{
                        minWidth: columns[0].minWidth,
                        width: columns[0].width,
                        paddingY: 3,
                        paddingX: 1,
                        bgcolor:
                          index % 2 === 0
                            ? StyleVariables["color-white"]
                            : StyleVariables["color-background"],
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"medium"}
                        color={
                          !deposit.cancelAt
                            ? StyleVariables["color-text"]
                            : StyleVariables["color-primary"]
                        }
                        textAlign={columns[0].align}
                      >
                        {deposit.refInvoiceNo?.replace(
                          `${RefInvoiceConstant.deposit}-`,
                          ""
                        ) || "-"}
                      </Typography>
                    </TableCell>
                    {/* /.Bill No */}

                    {/* Product Name */}
                    <TableCell
                      sx={{
                        minWidth: columns[1].minWidth,
                        width: columns[1].width,
                        paddingY: 3,
                        paddingX: 1,
                        bgcolor:
                          index % 2 === 0
                            ? StyleVariables["color-white"]
                            : StyleVariables["color-background"],
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"medium"}
                        color={
                          !deposit.cancelAt
                            ? StyleVariables["color-text"]
                            : StyleVariables["color-primary"]
                        }
                        textAlign={columns[1].align}
                      >
                        {deposit.productName}
                      </Typography>
                    </TableCell>
                    {/* /.Product Name */}

                    {/* Quantity */}
                    <TableCell
                      sx={{
                        minWidth: columns[2].minWidth,
                        width: columns[2].width,
                        paddingY: 3,
                        paddingX: 1,
                        bgcolor:
                          index % 2 === 0
                            ? StyleVariables["color-white"]
                            : StyleVariables["color-background"],
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"medium"}
                        color={
                          !deposit.cancelAt
                            ? StyleVariables["color-text"]
                            : StyleVariables["color-primary"]
                        }
                        textAlign={columns[2].align}
                      >
                        {deposit.totalQuantity}
                      </Typography>
                    </TableCell>
                    {/* /.Quantity */}

                    {/* Weight */}
                    <TableCell
                      sx={{
                        minWidth: columns[3].minWidth,
                        width: columns[3].width,
                        paddingY: 3,
                        paddingX: 1,
                        bgcolor:
                          index % 2 === 0
                            ? StyleVariables["color-white"]
                            : StyleVariables["color-background"],
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"medium"}
                        color={
                          !deposit.cancelAt
                            ? StyleVariables["color-text"]
                            : StyleVariables["color-primary"]
                        }
                        textAlign={columns[3].align}
                      >
                        {deposit.totalWeight}
                      </Typography>
                    </TableCell>
                    {/* /.Weight */}

                    {/* Deposit Price */}
                    <TableCell
                      sx={{
                        minWidth: columns[4].minWidth,
                        width: columns[4].width,
                        paddingY: 3,
                        paddingX: 1,
                        bgcolor:
                          index % 2 === 0
                            ? StyleVariables["color-white"]
                            : StyleVariables["color-background"],
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"medium"}
                        color={
                          !deposit.cancelAt
                            ? StyleVariables["color-text"]
                            : StyleVariables["color-primary"]
                        }
                        textAlign={columns[4].align}
                      >
                        <NumericFormat
                          value={deposit.depositPrice}
                          displayType="text"
                          thousandSeparator
                        />
                      </Typography>
                    </TableCell>
                    {/* /.Deposit Price */}

                    {/* Interest */}
                    <TableCell
                      sx={{
                        minWidth: columns[5].minWidth,
                        width: columns[5].width,
                        paddingY: 3,
                        paddingX: 1,
                        bgcolor:
                          index % 2 === 0
                            ? StyleVariables["color-white"]
                            : StyleVariables["color-background"],
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"medium"}
                        color={
                          !deposit.cancelAt
                            ? StyleVariables["color-text"]
                            : StyleVariables["color-primary"]
                        }
                        textAlign={columns[5].align}
                      >
                        <NumericFormat
                          value={deposit.interest}
                          displayType="text"
                          thousandSeparator
                        />
                      </Typography>
                    </TableCell>
                    {/* /.Interest */}

                    {/* Period */}
                    <TableCell
                      sx={{
                        minWidth: columns[6].minWidth,
                        width: columns[6].width,
                        paddingY: 3,
                        paddingX: 1,
                        bgcolor:
                          index % 2 === 0
                            ? StyleVariables["color-white"]
                            : StyleVariables["color-background"],
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"medium"}
                        color={
                          !deposit.cancelAt
                            ? StyleVariables["color-text"]
                            : StyleVariables["color-primary"]
                        }
                        textAlign={columns[6].align}
                      >
                        {deposit.period}
                      </Typography>
                    </TableCell>
                    {/* /.Period */}

                    {/* Bill No Next */}
                    <TableCell
                      sx={{
                        minWidth: columns[7].minWidth,
                        width: columns[7].width,
                        paddingY: 3,
                        paddingX: 1,
                        bgcolor:
                          index % 2 === 0
                            ? StyleVariables["color-white"]
                            : StyleVariables["color-background"],
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"medium"}
                        color={
                          !deposit.cancelAt
                            ? StyleVariables["color-text"]
                            : StyleVariables["color-primary"]
                        }
                        textAlign={columns[7].align}
                      >
                        {deposit.refInvoiceNoNext?.replace(
                          `${RefInvoiceConstant.deposit}-`,
                          ""
                        ) || "-"}
                      </Typography>
                    </TableCell>
                    {/* /.Bill No Next */}

                    <TableCell
                      sx={{
                        minWidth: columns[8].minWidth,
                        width: columns[8].width,
                        paddingY: 3,
                        paddingX: 1,
                        bgcolor:
                          index % 2 === 0
                            ? StyleVariables["color-white"]
                            : StyleVariables["color-background"],
                        position: "sticky",
                        right: columns[8].stickyPosition,
                        zIndex: 9,
                        "@media (max-width:1225px)": {
                          boxShadow: StyleVariables["shadow-table-sticky"],
                        },
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={3}
                      >
                        {!deposit.cancelAt ? (
                          <>
                            <ReceiptLongRoundedIcon
                              sx={{
                                fontSize: 32,
                                color: !deposit.cancelAt
                                  ? StyleVariables["color-main-icon"]
                                  : StyleVariables["color-menu-icon"],
                                cursor: "pointer",
                              }}
                              onClick={() => props.onClickBill(deposit.id)}
                            />
                            {deposit.type === RenewBillTypeKeyConstant.new ? (
                              <CancelOutlinedIcon
                                sx={{
                                  fontSize: 32,
                                  color: StyleVariables["color-menu-icon"],
                                  cursor: "pointer",
                                }}
                                onClick={() => props.onClickCancel(deposit)}
                              />
                            ) : null}
                          </>
                        ) : (
                          <Typography
                            variant="body2"
                            color={StyleVariables["color-primary"]}
                            fontWeight={"medium"}
                            textAlign={"center"}
                          >
                            ยกเลิกรายการ
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
      {props.isLoading ? (
        <Box
          marginY={4}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress
            size="40px"
            style={{ color: StyleVariables["color-textfield-icon"] }}
          />
        </Box>
      ) : !props.list?.length ? (
        <Typography
          variant="body2"
          color={StyleVariables["color-placeholder"]}
          fontWeight={"medium"}
          textAlign={"center"}
          marginY={4}
        >
          {props.noDataMessage}
        </Typography>
      ) : null}
    </>
  );
};

export default DepositList;
