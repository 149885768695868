import React, { useState } from "react";
import { Box, Grid, Typography, Button, Drawer } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import StyleVariables from "../assets/styles/Variable.module.scss";
import MainLayout from "../layouts/Main";
import SimpleAccordion from "../components/accordions/Simple";
import PurchaseForm from "../components/forms/Purchase";
import CustomerForm from "../components/forms/Customer";
import BillModal from "../components/modals/Bill";
import PurchaseBill from "../components/bills/Purchase";
import PurchaseCancelBill from "../components/bills/cancels/Purchase";
import SellAndBuyListTable from "../components/tables/SellAndBuyList";
import CancelBillModal from "../components/modals/CancelBill";
import LoadProgressing from "../components/LoadProgressing";
import InformationModal from "../components/modals/Information";
import AlertSnackbar from "../components/snackbars/Alert";
import {
  GetLatestTrades as GetLatestTradesService,
  GetTradeById as GetTradeByIdService,
  CreateTrade as CreateTradeService,
  SubmitTradeById as SubmitTradeByIdService,
  CancelTrade as CancelTradeService,
} from "../services/Trade";
import { Create as CreateCustomerService } from "../services/Customer";
import { OnPrintPdf as OnPrintPdfService } from "../services/Storage";
import {
  Trade as TradeModel,
  CreateTrade as CreateTradeModel,
  CancelTrade as CancelTradeModel,
} from "../models/Trade";
import { CancelReasonForm as CancelReasonFormModel } from "../models/MasterData";
import { Create as CreateCustomerModel } from "../models/Customer";
import { KeysType as KeysTypeConstant } from "../constants/Payment";
import { Type as TypeGoldConstant } from "../constants/Gold";
import {
  ScrollToTop as ScrollToTopUtil,
  ScrollToElementId as ScrollToElementIdUtil,
} from "../utils/ScrollToElement";

type Props = {};

const Purchase = observer((props: Props) => {
  const { MainStore, TradeStore, CustomerStore } = useStores();
  const [isBillModalOpen, setIsBillModalOpen] = useState<boolean>(false);
  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [trades, setTrades] = useState<any[]>([]);
  const [isCompleteBillModalOpen, setIsCompleteBillModalOpen] =
    useState<boolean>(false);
  const [isCompleteBillCancelModalOpen, setIsCompleteBillCancelModalOpen] =
    useState<boolean>(false);
  const [trade, setTrade] = useState<TradeModel | null>(null);
  const [isCancelBillModalOpen, setIsCancelBillModalOpen] =
    useState<boolean>(false);
  const [isLoadProgressing, setIsLoadProgressing] = useState<boolean>(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState<boolean>(false);
  const [scrollToElementId, setScrollToElementId] = useState<string>("");
  const [messageFailModal, setMessageFailModal] = useState<string>("");
  const [isOpenSuccessSnackbar, setIsOpenSuccessSnackbar] =
    useState<boolean>(false);

  const onSubmit = () => {
    const isValid = TradeStore.onValidateForm();
    if (!isValid) {
      setScrollToElementId("purchase-gold-old-information-content");
      setMessageFailModal(
        "กรุณาตรวจสอบข้อมูลทองเก่าให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
      );
      setIsFailModalOpen(true);
      return;
    }
    setIsBillModalOpen(true);
  };

  const onReset = () => {
    TradeStore.onClearStore();
    CustomerStore.onClearStore();
    ScrollToTopUtil();
  };

  const onOpenList = () => {
    getTrades();
    setIsOpenList(true);
  };

  const onCloseList = () => {
    setIsOpenList(false);
    setTrades([]);
  };

  const getTrades = async () => {
    setIsLoadingList(true);
    const response = await GetLatestTradesService(TypeGoldConstant.purchase);
    response.success && setTrades(response.data);
    setIsLoadingList(false);
  };

  const onOpenCompleteBillModal = async (id: string) => {
    setIsLoadProgressing(true);
    const response = await GetTradeByIdService(id);
    setIsLoadProgressing(false);
    const { payments, ...data } = response.data;
    if (response.success) {
      setIsOpenList(false);
      setTrade({
        type: "",
        paymentType: KeysTypeConstant.allCash,
        currentStatus: "good",
        standardExcellent: 0,
        standardGood: 0,
        standardFair: 0,
        standardPoor: 0,
        ...data,
      });
      !data.cancelAt
        ? setIsCompleteBillModalOpen(true)
        : setIsCompleteBillCancelModalOpen(true);
    }
  };
  const onCloseCompleteBillModal = () => {
    setIsCompleteBillModalOpen(false);
    setTrade(null);
  };
  const onCloseCompleteCancelBillModal = () => {
    setIsCompleteBillCancelModalOpen(false);
    setTrade(null);
  };

  const onOpenCancelBillModal = (trade: TradeModel) => {
    onCloseList();
    setTrade(trade);
    setIsCancelBillModalOpen(true);
  };

  const onCloseCancelBillModal = () => {
    setIsCancelBillModalOpen(false);
    setTrade(null);
  };

  const onCreatePurchase = async () => {
    setIsBillModalOpen(false);
    setIsLoadProgressing(true);
    const customerBody: CreateCustomerModel | null = CustomerStore.getBody();
    const { payment, ...tradeBody } = TradeStore.getBody();
    const body: CreateTradeModel = {
      ...tradeBody,
    };
    let customerId: string = "";
    // Create Customer
    if (customerBody) {
      const responseCustomer = await onCreateCustomer(customerBody);
      if (!responseCustomer) return;
      customerId = responseCustomer?.id;
    }
    // /.Create Customer

    // Create Trade
    const responseTrade = await onCreateTrade({
      ...body,
      ...(customerId && { customerId }),
    });
    if (!responseTrade) return;
    // /.Create Trade

    // Submit PDF
    await onSubmitTrade(responseTrade.id);
    // /.Submit PDF

    TradeStore.onClearStore();
    TradeStore.onSetIsShowAll(true);
    CustomerStore.onClearStore();
    setIsLoadProgressing(false);
    setIsOpenSuccessSnackbar(true);
  };

  const onCreateCustomer = async (body: CreateCustomerModel) => {
    const response = await CreateCustomerService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลลูกค้าไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };

  const onCreateTrade = async (body: CreateTradeModel) => {
    const response = await CreateTradeService(TypeGoldConstant.purchase, body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message ||
          "สร้างข้อมูลการรับซื้อไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };
  const onSubmitTrade = async (id: string) => {
    const response = await SubmitTradeByIdService(id);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message ||
          "เกิดข้อผิดพลาดในการสร้างบิล กรุณาพิมพ์บิลใหม่ในรายการประวัติการขาย"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
  };
  const onCloseFailModal = () => {
    setIsFailModalOpen(false);
    setMessageFailModal("");
    scrollToElementId && ScrollToElementIdUtil(scrollToElementId);
    setScrollToElementId("");
  };
  const onCancelTrade = async (form: CancelReasonFormModel) => {
    const body: CancelTradeModel = {
      cancelReasonId: form.reason.id,
      cancelMisc: form.misc,
    };
    if (trade?.id) {
      const response = await CancelTradeService(trade?.id, body);
      onCloseCancelBillModal();
      if (!response?.success) {
        setMessageFailModal(
          `${
            response.message ||
            "ยกเลิกรายการรับซื้อไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
          } (${response.code})`
        );
        setIsFailModalOpen(true);
        return;
      }
      onOpenList();
    }
  };

  const onPrintBillPdfHistory = async (id: string) => {
    setIsCompleteBillModalOpen(false);
    setIsLoadProgressing(true);
    const response = await OnPrintPdfService(id);
    setIsLoadProgressing(false);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "เกิดข้อผิดพลาดในการพิมพ์ข้อมูลบิลการรับซื้อ"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      return false;
    }
  };

  return (
    <MainLayout>
      <>
        <Box
          display={{
            xs: MainStore.isOpenSidebar ? "block" : "flex",
            md: "flex",
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          textAlign={"center"}
        >
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={StyleVariables["color-header"]}
          >
            การรับซื้อ
          </Typography>
          <Box>
            <Button variant="contained-buy" onClick={onOpenList}>
              ดูประวัติการรับซื้อ
            </Button>
          </Box>
        </Box>
        <Box marginTop={4}>
          {/* Form Product */}
          <SimpleAccordion
            id="purchase-gold-old-information"
            title="ข้อมูลทองเก่า"
            defaultExpanded={true}
          >
            <PurchaseForm />
          </SimpleAccordion>
          {/* Form Product */}

          {/* Form Customer */}
          <SimpleAccordion
            id="customer-information"
            title="ข้อมูลลูกค้า/ภาพสินค้า"
          >
            <CustomerForm type="TRADE" />
          </SimpleAccordion>
          {/* /.Form Customer */}
        </Box>
        {/* Button */}
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          marginTop={3}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Button variant="outlined" fullWidth onClick={onReset}>
              เริ่มใหม่
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Button variant="contained-success" fullWidth onClick={onSubmit}>
              ทำรายการ
            </Button>
          </Grid>
        </Grid>
        {/* /.Button */}

        {/* Preview Bill Modal */}
        <BillModal
          isOpen={isBillModalOpen}
          title={"รับซื้อ"}
          onSaveAndPrint={onCreatePurchase}
          onClose={() => {
            setIsBillModalOpen(false);
          }}
        >
          <PurchaseBill trade={TradeStore.form} />
        </BillModal>
        {/* /.Preview Bill Modal */}

        {/*  Table List */}
        <Drawer anchor={"bottom"} open={isOpenList} onClose={onCloseList}>
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
              paddingY={1}
            >
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                ประวัติการรับซื้อล่าสุด
              </Typography>
              <CloseIcon
                sx={{
                  fontSize: 32,
                  color: StyleVariables["color-mainIcon"],
                  cursor: "pointer",
                }}
                onClick={onCloseList}
              />
            </Box>
            <Box marginTop={2}>
              <SellAndBuyListTable
                list={trades}
                isLoading={isLoadingList}
                noDataMessage="ไม่มีข้อมูลการรับซื้อวันนี้"
                onClickBill={(id: string) => {
                  onOpenCompleteBillModal(id);
                }}
                onClickBillCancel={(id: string) => {
                  onOpenCompleteBillModal(id);
                }}
                onClickCancel={(trade: TradeModel) => {
                  onOpenCancelBillModal(trade);
                }}
              />
            </Box>
          </>
        </Drawer>
        {/* /.Table List */}

        {/* Preview Complete Bill Modal */}
        <BillModal
          isOpen={isCompleteBillModalOpen}
          title={
            !trade?.deposits?.id ? "รับซื้อ (สำเนา)" : "รับซื้อ ตัดขาย (สำเนา)"
          }
          isComplete={true}
          onPrint={() => {
            onPrintBillPdfHistory(trade?.receiptCopy?.id || "");
          }}
          onClose={onCloseCompleteBillModal}
        >
          <PurchaseBill trade={trade} />
        </BillModal>
        {/* Preview Complete Bill Modal */}

        {/* Preview Complete Cancel Bill Modal */}
        <BillModal
          isOpen={isCompleteBillCancelModalOpen}
          title={"ยกเลิก รับซื้อ (สำเนา)"}
          isComplete={true}
          onPrint={() => {
            onPrintBillPdfHistory(trade?.cancelReceiptCopy?.id || "");
          }}
          onClose={onCloseCompleteCancelBillModal}
        >
          <PurchaseCancelBill trade={trade} />
        </BillModal>
        {/* Preview Complete Cancel Bill Modal */}

        {/* Cancel Bill Modal */}
        <CancelBillModal
          isOpen={isCancelBillModalOpen}
          onClose={onCloseCancelBillModal}
          onSubmit={(form: CancelReasonFormModel) => {
            onCancelTrade(form);
          }}
        />
        {/* /.Cancel Bill Modal */}

        {/* Error Modal */}
        <InformationModal
          isOpen={isFailModalOpen}
          title="ทำรายการไม่สำเร็จ"
          onClose={onCloseFailModal}
        >
          <>
            <Box textAlign={"center"}>
              <Typography variant="body2" fontWeight={"regular"}>
                {messageFailModal}
              </Typography>
            </Box>
            <Button
              variant="contained"
              fullWidth
              sx={{ marginTop: 5 }}
              onClick={onCloseFailModal}
            >
              ตกลง
            </Button>
          </>
        </InformationModal>
        {/* /.Error Modal */}

        <LoadProgressing isOpen={isLoadProgressing} />

        <AlertSnackbar
          type="success"
          isOpen={isOpenSuccessSnackbar}
          description="สร้างรายการรับซื้อสำเร็จ"
          onClose={() => setIsOpenSuccessSnackbar(false)}
        />
      </>
    </MainLayout>
  );
});

export default Purchase;
